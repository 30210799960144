/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Inputs
-------------------------------- */
/*
Styling for all types of inputs - checkboxes, radios, selects, textareas and tooltips are imported.
*/
// % protected region % [Change input variables and standard input styles here] off begin
/******* INPUT VARIABLES *******/
$admin-input-font-size: $admin-base-font-size !default;
$admin-input-radius: $radius !default;

$admin-input-md: calc(#{$btn-font-size} + 0.2rem) !default;
$admin-input-lg: calc(#{$btn-font-size} + 2rem) !default;

$admin-input-sm-width: calc(50% - #{$admin-space-xs}) !default;
$admin-input-md-width: calc(100% - #{$admin-space-xs}) !default;

// z-index fix for checkboxes
$admin-z-index-back: 1 !default;
$admin-z-index-front: 2 !default;

//Reset the fieldset
fieldset {
	padding: 0rem;
	border: none;
}

/*******STANDARD INPUTS*******/
/*Standard styles for general inputs such as text, number and password. These styles will control aspects such as width, font-size and line-height but can be combined
with other modular classes for positioning and other overrides*/

input[type="text"],
input[type="number"],
input[type="password"],
input[type="time"],
input[type="date"],
input[type="email"],
textarea,
select {
	display: inline-flex;
	position: relative;
	white-space: nowrap;
	line-height: 1;
	font-size: $admin-input-font-size;
	vertical-align: top;
	border-radius: $admin-input-radius;
	margin: $admin-space-xxs 0 $admin-space-xs 0;
	width: auto;
	@include truncate;
	color: $admin-grey-8;
	font-weight: $admin-text-regular;
	border: convert-rem(1px) solid rgba($admin-grey-4, 1);

	&::placeholder {
		color: $admin-grey-4;
		font-weight: $admin-text-light;
		opacity: 1;
	}

	&:focus {
		outline: none;
	}

	&:hover,
	&:active,
	&:focus {
		color: $admin-grey-6;
		border: convert-rem(1px) solid $admin-grey-1;
		background-color: rgba($admin-grey-1, 0.02);
		@include transform-timing($admin-animation-time);
	}
}

// % protected region % [Change input variables and standard input styles here] end

// % protected region % [Change input group styles here] off begin
/*******INPUT GROUP*******/
/*This is an input group. It collects multiple components - labels, tooltips, text areas and other types of inputs and structures them within a grid area. Multiple
items can be placed within a single grid area, as long as they are contained within a wrapper div (to prevent items floating above each other).*/
.input-group {
	position: relative;
	display: grid;
	grid-template-rows: min-content min-content auto;
	grid-template-columns: 1fr auto;
	grid-template-areas: "label tooltip" "input input" "validation validation";
	margin: $admin-space-sm auto;
	width: auto;
	max-width: 100%;
	min-width: convert-rem(250px);
	@include media(xs, sm, md) {
		min-width: convert-rem(150px);
	}
	cursor: default;
	outline: none;

	>label {
		display: inline-block;
		color: $admin-grey-8;
		font-weight: $admin-text-regular;
		grid-area: label;
		align-self: center;
		cursor: default;
	}

	input {
		grid-area: input;
		outline: none;
		//margin and padding for inputs without type
		padding: $admin-space-xs $admin-space-xs;
		margin: $admin-space-xs 0;
	}

	>.side-button-container {
		grid-area: input;
		padding: $admin-space-xs 0;
		display: flex;

		>input {
			outline: none;
			//margin and padding for inputs without type
			padding: $admin-space-xs $admin-space-xs;
			margin: 0;
			flex: 1;
		}
		>button {
			// Any button in the container should be the height of the input
			height: 100%;
			width: $admin-space-xxxl;
		}
	}

	.tooltip {
		grid-area: tooltip;
		justify-self: flex-end;
		cursor: default;
	}

	.sub-description {
		grid-area: label;
	}

	/*The two bottom classes in this declaration are generated from the dropdown library we are using, and should be moved later on as it is not appropriate for
	them to be in this area. Text area is fine where it is and can stay where it is*/
	textarea,
	&__date {
		grid-area: input;
	}

	textarea {
		height: convert-rem(200px);
		resize: none;
	}

	&:hover {
		* {
			@include transform-timing($admin-animation-time);
		}
	}

	&:focus {
		label {
			outline: none;
			border: convert-rem(1px) solid $admin-black;
		}
	}

	/*This will re-arrange the elements within the input-group so that inputs and labels can be placed side-by-side to maximise space*/
	&.input-group-inline {
		grid-template-areas: "label input tooltip" "validation validation validation";
		grid-template-columns: max-content 1fr min-content;
		align-items: center;

		label {
			margin-right: $admin-space-sm;
		}

		input {
			flex: auto;
		}

		.tooltip {
			top: 1.2rem;
		}
	}

	&.input-group-block {
		flex-direction: column;
	}

	&--error {
		label {
			color: $admin-color-support-red;
		}

		input,
		textarea {
			border: convert-rem(1px) solid $admin-color-support-red;
		}

		.input-group__error-text {
			grid-area: validation;
			@include fadeIn(0.3s);
			animation-iteration-count: 1;

			p {
				margin: 0;
				padding: 0;
				color: $admin-color-support-red;
			}
		}

		&.input-group__dropdown {
			.input-group__error-text {
				animation-iteration-count: 0;
			}
		}
	}

	&.input-group--is-required {
		.required {
			margin-left: $admin-space-xs;
			color: $admin-color-support-red;
			font-size: $admin-base-font-size;
			font-weight: $admin-text-medium;
		}
	}

	//TEXT AREA
	@import 'textarea.scss';

	//TOOLTIPS
	@import 'tooltip.scss';

	//SELECTS
	@import 'select.scss';
}
// % protected region % [Change input group styles here] end

/*******INPUT BUTTONS*******/
// % protected region % [Change input buttons styles here] on begin
/*This is a button which clears the content within an input*/
.input-group {

	// added auto and 100% height to ensure icon is centered
	.icon-x,
	.icon-cross {
		height: 100%;
		background: transparent;
		border: none;
		position: absolute;
		right: 0.6rem;
		top: auto;
		bottom: auto;
		outline: none;

		&:before {
			display: none;
		}

		&:hover,
		&:active,
		&:focus {
			cursor: pointer;
			color: $admin-color-support-red;
			@include transform-timing($admin-animation-time);
		}
	}
}
// % protected region % [Change input buttons styles here] end
/*******Date & Time Picker*******/
//If styling required create the file and import here//



/*This will organise a series of inputs into a row as oppsed to a column. Inputs will wrap, whereas header and tooltip occupy half of the
top row*/
// % protected region % [Change radio and checkbox wrapper styles here] off begin
.input-group-wrapper__radio,
.input-group-wrapper__checkbox {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	@include width-height-fit-content(width);
	min-width: convert-rem(350px);

	&.input-group-block,
	&.input-group-wrapper--block {
		flex-direction: column;
	}

	/*An input group with a tooltip and header side by side, with inputs inline underneath it*/
	&.input-group-inline,
	&.input-group-wrapper--inline {
		display: flex;
		flex-wrap: wrap;


		p {
			width: 100%;
		}


		.tooltip {
			width: 50%;

			&__content {
				margin-left: auto;
			}
		}
	}

	//TOOLTIPS
	@import 'tooltip.scss';

	>.tooltip {
		right: -4rem;
		left: unset;
		top: 1.5rem;
	}
}
.input-group__radio,
.input-group__checkbox {
	input {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
// % protected region % [Change radio and checkbox wrapper styles here] end

/*******Checkboxes*******/
@import 'checkbox.scss';


/*******Radio*******/
@import 'radio.scss';



// % protected region % [Change disabled inputs styles here] off begin
/*******DISABLED AND READONLY INPUTS*******/
//Imports are after all input styles to override all styling on disabled and static

input[disabled],
input[disabled]+label,
textarea[disabled],
input.disabled {
	cursor: not-allowed;
	opacity: 0.6;
	background-color: $admin-grey-2;

	&:hover,
	&:focus,
	&:active {
		border: convert-rem(1px) solid $admin-grey-4;
		background-color: $admin-grey-2;
		color: $admin-grey-6;
	}

}

input[readonly],
input[readonly]+label,
textarea[readonly] {
	cursor: text;
	background-color: $admin-grey-0;
	opacity: 0.8;

	&:hover,
	&:focus,
	&:active {
		border: convert-rem(1px) solid $admin-grey-4;
		background-color: $admin-grey-0;
		color: $admin-grey-8;
	}
}

.input-group--static {
	pointer-events: none;

	input {
		background: transparent;
		border: none;
		color: $admin-grey-8;
		opacity: 1;

		&:checked {
			&:after {
				color: $admin-grey-6;
				background-color: $admin-grey-6;
			}
		}

		&:hover {
			background: transparent;
			border: none;
		}
	}

	//Handles when there is no value for the input
	&.input-group--empty {
		label {
			&:after {
				content: '- No Value -';
				font-style: italic;
				display: block;
				color: $admin-grey-4;
				font-weight: $admin-text-regular;
				margin-top: $admin-space-xs;
			}
		}

		&.input-group__checkbox,
		&.input-group__radio,
		&.input-group__textarea {
			label {
				&:after {
					display: none;
				}
			}

			input {
				&:checked:after {
					color: $admin-grey-4;
				}
			}
		}
	}
}
// % protected region % [Change disabled inputs styles here] end
// % protected region % [Add additional inputs styles here] off begin
// % protected region % [Add additional inputs styles here] end