﻿.timeline {
	height: 100%;
	display: flex;
	flex-direction: column;

	.resize-handle {
		width: 100%;
	}

	.timeline-cards-wrapper {
		min-height: 100px;
		height: 100%;
		display: grid;
		grid-template-rows: 35px 1fr;
		overflow: hidden;

		h6 {
			margin: $spacing-8 $spacing-16;
		}
	}

	.timeline-attributes-wrapper {
		display: flex;
		overflow-x: auto;
		overflow-y: auto;
		background-color: $color-neutral-50;
		color: $color-text-dark;

		.timeline-attributes {
			display: grid;
			grid-auto-flow: column;
			width: 100%;

			.timeline-attribute-column {
				position: relative;
				display: grid;
				min-width: 274px;
				grid-template-rows: min-content 1fr;

				::-webkit-scrollbar {
					width: 5px;
				}

				.attribute-disabled-overlay {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba($grey-0, 0.7);
				}

				.column-header {
					display: grid;
					grid-template-columns: min-content 1fr min-content;
					font-weight: 800;
					background-color: $color-neutral-hover-100;
					padding: $spacing-4 $spacing-16;
					align-items: center;

					.icon {
						height: 19px;

						&:before,
						&:after {
							width: 16px;
							height: 16px;
							font-size: 1.2rem;
							margin-right: $spacing-6;
						}
					}

					p {
						margin: 0;
					}
				}

				.column-body {
					overflow: auto;

					.timeline-tiers {
						margin: $spacing-6 $spacing-12 $spacing-16;
						padding: $spacing-2;
						overflow-y: auto;

						.timeline-tier {
							h6, p {
								margin: 0;
							}

							& + .timeline-tier {
								margin-top: $spacing-10;
							}

							.timeline-tier-header {
								margin: 0 0 $spacing-6 0;
								font-size: 14px;
								font-weight: 600;
							}

							.timeline-tier-body {
								display: flex;
								flex-wrap: wrap;
								gap: $spacing-6;
							}
						}
					}
				}
			}
		}
	}

	// Put this style here for the drag-n-drop
	.timeline-tier-item {
		width: 260px;
		padding: $spacing-8;
		color: $color-text-dark;
		background-color: $white;
		border-radius: 4px;
		display: grid;
		grid-template-areas:
			"node-name carver-score"
			"node-attribute carver-score";
		align-items: center;
		column-gap: $spacing-4;
		cursor: grab;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);

		p {
			margin: 0;
		}

		.name {
			grid-area: node-name;
			align-self: start;
			font-size: 14px;
			font-weight: 600;
			line-height: 1.2;
		}

		.attribute {
			grid-area: node-attribute;
			color: $color-neutral-200;
			align-self: self-end;
			font-size: 13px;
		}

		.carver-score {
			grid-area: carver-score;
			justify-self: flex-end;
			width: 42px;
			height: 42px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: initial;
			color: $white;

			h6 {
				font-size: 1rem;
			}

			@include metric-score-color();
		}
	}

	.timeline-tracks {
		min-height: 35px;
		height: 100%;
		overflow-y: auto;
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		background-color: $color-neutral-primary-text-900;

		&.loading {
			background-color: $color-neutral-800;
		}

		&__header {
			height: 35px;
			display: grid;
			grid-template-areas: 'left right';
			align-items: center;
			padding: 0 $spacing-16;

			.left {
				grid-area: left;

				h6 {
					margin: 0;
				}
			}

			.right {
				display: flex;
				gap: $spacing-12;
				grid-area: right;
				justify-self: end;

				.btn {
					padding: 0 $spacing-10;
					margin: 0;
					height: inherit;
					font-weight: 700;
					color: $white;
					letter-spacing: normal;

					&:before,
					&:after {
						margin: 0
					}
				}

				.date-navigator {
					display: flex;

					.input-group {
						margin: 0;
						justify-content: center;

						input {;
							padding: 2px $spacing-8;
							color: white;
							min-width: 0;
							width: 90px;
							cursor: pointer;
							text-align: center;

							&:hover,
							&:focus,
							&:active {
								background-color: #2c3e50;
							}
						}
					}

					.btn {
						padding: 0;
					}
				}

				.timeline-view-active {
					background-color: #2c3e50;
				}
			}
		}

		.fc {
			color: black;
			background-color: white;

			&.fc-theme-standard {
				td, th {
					border-color: #AAA;
				}
			}

			.fc-icon:before {
				font-family: inherit;
			}

			.fc-timeline-body {
				background-color: lightgray;

				.fc-scrollgrid-sync-table {
					border-bottom: 1px solid var(--fc-border-color);
				}
			}

			.fc-button {
				padding: 0.2em 0.8em;
				background-color: transparent;
				border-color: transparent;

				&:focus {
					box-shadow: none;
					outline: 1px solid $color-support-blue;
				}

				&.fc-button-active {
					background-color: var(--fc-button-bg-color);
					border-color: var(--fc-button-active-border-color);
					color: var(--fc-button-text-color);
				}

				.fc-icon {
					vertical-align: text-bottom;
				}
			}

			.fc-header-toolbar, .fc-footer-toolbar {
				height: 35px;
				margin: 0;
				padding: 0.5rem 1rem;

				.fc-toolbar-title {
					color: white;
					font-size: convert-rem(18px);
					font-weight: $text-heavy;
					letter-spacing: 0.03em;
				}

				.fc-scrollgrid-section > th:first-child {
					border-right: none;
				}

				.fc-resource-timeline-divider {
					visibility: hidden;
				}
			}

			.fc-timeline-header-row + .fc-timeline-header-row {
				.fc-timeline-slot-frame {
					justify-content: center;

					.fc-timeline-slot-cushion {
						padding: 0 1px;
						font-size: 80%;
					}
				}
			}

			.fc-datagrid-header {
				.fc-datagrid-cell-frame {
					justify-content: center;

					.fc-datagrid-cell-main {
						.add-track-button {
							background-color: $color-success-500;
							color: $white;

							&:before {
								margin-right: $spacing-4;
								font-size: 1.4rem;
							}
						}
					}
				}
			}

			.fc-datagrid-body {
				font-size: 1rem;
				border-bottom: 1px solid var(--fc-border-color);

				.fc-datagrid-cell-cushion {
					padding: 1rem;

					.fc-datagrid-cell-main {
						.add-track-button {
							background-color: $color-success-500;
						}

						.track-wrapper {
							display: grid;
							grid-template-rows: 1fr;
							grid-template-columns: minmax(0, 1fr) 24px;
							align-items: center;
							justify-content: space-between;

							.track-name {
								grid-area: 1 / 1 / 1 / 2;
								width: 100%;
								min-width: 0;

								p {
									text-overflow: ellipsis;
									margin: 0;
									overflow: hidden;
								}
							}

							.track-context-menu {
								grid-area: 1 / 2 / 1 / 3;
								display: flex;

								.btn {
									padding: 0;
									align-items: center;
									max-height: 24px;

									&:after {
										color: $color-text-dark;
										margin: 0;
									}
								}
							}
						}
					}
				}
			}

			.react-contexify {
				border-radius: 4px;
				padding: $spacing-6 0;
				min-width: 160px;
				box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.1);

				.react-contexify__item {
					padding: $spacing-12 $spacing-16;

					.react-contexify__item__content {
						display: inline-block;

						button {
							background-color: transparent;
							color: $color-text-dark;
							text-transform: capitalize;
						}
					}

					&:hover {
						background-color: $grey-0;

						.react-contexify__item__content {
							background-color: $grey-0;
						}
					}
				}
			}

			.fc-timeline-slot {
				text-align: left;
				font-weight: normal;

				.fc-timeline-slot-frame {
					justify-content: flex-start;
				}

				a {
					color: black;
					overflow: hidden;
					text-overflow: ellipsis;

					&:hover {
						border: none;
					}
				}

				&.fc-timeline-slot-lane {
					border-color: rgba(0, 0, 0, 0.1);
				}
			}

			.fc-event {
				background-color: white;
				box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
				border-radius: 0.2rem;
				border: 1px solid transparent;

				&:hover {
					border-color: gray;
				}
			}

			.fc-license-message {
				display: none;
			}

			.fc-event-draggable {
				cursor: grab;
			}

			.fc-event-dragging {
				box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
				cursor: grabbing;
			}
		}

		.timeline-track-item {
			white-space: nowrap;
			overflow: hidden;
			display: inline-flex;
			align-items: center;
			vertical-align: bottom;
			padding: 0.2em;
			font-size: 0.8rem;
			font-weight: bold;
			width: 100%;
			color: black;

			.icon {
				margin-right: 0.2rem;

				&:before {
					font-weight: bold;
					font-size: 150%;
				}
			}

			.text {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.timeline-track-item-popup {
			.popup {
				padding: 0.5rem;
				max-width: 300px;
			}

			.item {
				display: grid;
				grid-template-areas:
					"date date"
					"carver title"
					"carver attribute";
				grid-auto-columns: 45px auto;
				grid-auto-rows: min-content auto 15px;

				button.delete {
					border: none;
					background: none;
					padding: 0 0.1rem;
					cursor: pointer;
					border-radius: 30%;
					color: darkred;

					position: absolute;
					top: 0.5rem;
					right: 0.5rem;

					.icon-only:before {
						font-size: 1.2rem;
					}

					&:hover {
						outline: 1px solid rgba(0, 0, 0, 0.5);
					}
				}

				.date {
					grid-area: date;
					display: flex;
					flex-direction: column;
					font-size: 11px;
					line-height: 11px;
					margin-right: 1.8rem;
					margin-bottom: 5px;
				}

				.carver {
					grid-area: carver;
					width: 42px;
					height: 42px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 16px;
					line-height: initial;
					color: white;
					font-weight: bold;

					@include metric-score-color();
				}

				.title {
					grid-area: title;
					display: flex;
					vertical-align: bottom;
					font-weight: bold;
					font-size: 14px;
					line-height: 14px;
					flex-wrap: nowrap;
					align-items: center;
					padding-top: 2px;

					.icon {
						margin-right: 0.2rem;

						&:before {
							font-size: 120%;
						}
					}
				}

				.attribute {
					grid-area: attribute;
					font-size: 12px;
					line-height: 12px;
					margin-left: 5px;
				}
			}
		}
	}
}

.edit-timeline-track-item-modal {
	.modal-content__header {
		margin-bottom: $spacing-10;

		.node-name {
			display: grid;
			grid-template-columns: min-content min-content 1fr;
			grid-template-rows: 1fr;
			align-items: center;
			gap: $spacing-8;

			.carver {
				width: 42px;
				height: 42px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 16px;
				color: white;
				font-weight: bold;
				line-height: initial;

				@include metric-score-color();
			}

			h6 {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.modal-content__description {
		color: $color-neutral-disabled-bg-500;
		margin-bottom: $spacing-8;
	}
	
	form {
		input[readonly] {
			@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(2px), get-contrasting-text-color($background));
		}
	}
}
