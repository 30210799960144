.engine-2-page {
	grid-area: body;
	height: 100%;
	width: 100%;
	max-width: 100vw;
	position: relative;
	background-color: $page-background;

	.__layout {
		overflow: hidden;
		height: inherit;
		width: inherit;
		display: flex;

		> .__content {
			min-width: 0px;
			width: 100%;
			height: 100%;
			max-width: 100vw;
			max-height: 100vh;
			overflow: inherit;
		}
	}

	.scene-button-container {
		width: inherit;
		position: relative;
	}

	.scene-button-group {
		position: absolute;
		bottom: 50px;
		left: 50%;
		transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		z-index: 0;

		background-color: $page-background;
		background-color: transparentize($page-background, 0.1);
		color: $color-primary;
		border-radius: 4px;
		padding: 16px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
		grid-template-areas: "graphButton mapButton";
		column-gap: 8px;

		.scene-button {
			padding: 4px 16px;
			text-transform: uppercase;

			&:before {
				font-size: 24px;
				margin-right: 8px;
			}
			&:not(:hover):not(.active) {
				color: $color-primary-800 !important;
			}
		}

		.scene-button_graph {
			grid-area: graphButton;
		}

		.scene-button_map {
			grid-area: mapButton;
		}
	}
}
