.system-metrics-configuration {
	border: 1px solid $color-neutral-primary-text-900;

	.header {
		height: 48px;
		background-color: $color-neutral-primary-text-900;
		color: $white;
		padding: 0 $spacing-24;
		display: flex;
		justify-content: space-between;
		align-items: center;

		> h5 {
			font-weight: bold;
			margin: 0;
		}

		> .header-toggle {
			display: flex;
			gap: $spacing-12;

			h6 {
				display: inline-block;
				margin: auto;
			}
		}
	}

	.metrics-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: $spacing-8;
		padding: $spacing-12;

		.metric {
			grid-column: span 1;
			border: 1px solid black;
			padding: 0 $spacing-24;
			height: 56px;
			margin: 10px;
			display: grid;
			grid-template-columns: 1fr 76px;
			align-items: center;
			justify-content: space-between;

			> h5 {
				margin: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&__buttons {
				button {
					justify-self: end;
					padding: 0;

					&:after {
						font-size: 1.5rem;
						color: $color-neutral-primary-text-900;
						margin-left: $spacing-12;
						height: $spacing-24;
					}

					&.icon-bin-full::after {
						color: $color-danger-500;
					}
				}
			}

			&.empty {
				@keyframes fadeIn {
					0% {
						opacity: 0;
					}
					100% {
						opacity: 1;
					}
				}
				cursor: pointer;
				background-color: $color-primary-50;
				border: 2px dashed $color-neutral-secondary-text-700;
				border-radius: 2px;
				animation: fadeIn 0.3s;

				&:hover {
					filter: brightness(96%);
				}

				h5 {
					justify-self: start;
				}
			}
		}
	}
}