.copy-text-box {
	display: flex;
	flex-direction: column;
	gap: $spacing-4;

	.label {
	  font-family: $body-font-family;
	  font-weight: $text-bold;
	  color: $color-neutral-primary-text-900;
	}

	.text-box {
		display: grid;
		grid-template-columns: auto 35px;
		align-items: center;
		padding: $spacing-16 $spacing-8;
		border: 1px solid $color-neutral-800;
		border-radius: 2px;

		p {
			margin: 0;
			white-space: nowrap;
			overflow: hidden;
			box-shadow: 4px 0 1px -2px $color-neutral-200;
		}
	}

	.copy-value-btn {
		background: none;
		border: none;
		cursor: pointer;

		&:after {
			margin: 0;
			font-size: 18px;
		}
	}
}