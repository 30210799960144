/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Login
-------------------------------- */
/*
Login, register and reset password pages
*/

// % protected region % [Change login, register and reset password styles here] on begin
.login-page,
.reset-password-page,
.registration-page,
.twofactor-authentication-page,
.twofactor-verify-page {
	display: grid;
	grid-template-rows: 250px 1fr;
	justify-items: center;
	height: 100vh;

	.logo {
		place-self: center;
		width: 25%;
		max-width: 150px;
		margin-top: 2rem;
		margin-bottom: 0.5rem;
	}

	.login-page-content,
	.reset-password-content,
	.registration-page-content,
	.twofa-page-content,
	.verify-page-content {
		background: $white;
		padding: $spacing-32;
		border-radius: 4px;
		height: max-content;
		width: 100%;

		.login,
		.reset-password,
		.register,
		.authenticate,
		.verify-two-factor-auth {
			display: flex;
			align-items: flex-start;
			align-content: center;
			flex-direction: column;
			margin: 0 auto;
			justify-content: center;

			@include media(xs, sm, md) {
				height: auto;
				margin: $space-sm auto;
			}

			h2 {
				margin: 0 0 $space-lg 0;

				@include media(xs, sm, md) {
					margin: 0 0 $space-md 0;
				}
			}

			.input-group {
				width: 100%;
				max-width: unset;
				margin: .5rem 0;

				label {
					font-weight: $text-regular;
				}

				.icon-look {
					position: absolute;
					right: 0;
					top: 2.6rem;

					&:hover {
						text-decoration: none;
						color: $color-secondary;
						@include transform-timing($animation-time);
					}

					&:before {
						margin-right: 0;
					}
				}
			}

			.btn-group {
				display: flex;
				justify-content: space-between;
				width: 100%;
				align-items: center;
				margin-top: $space-md;

				@include media(xs, sm, md) {
					margin-top: $space-xs;
				}

				@media screen and (max-width: 620px) {
					flex-direction: column;
				}

				.btn {
					padding: $space-md;
					display: flex;
					margin: 0;
					flex-grow: 1;

					@include media(xs, sm, md) {
						padding: $space-xs;
						margin: $space-xs auto;
					}

					@media screen and (max-width: 620px) {
						width: 100%;
						box-sizing: border-box;
					}

					&.btn--secondary {
						margin-left: $space-xl;

						@media screen and (max-width: 620px) {
							margin-left: 0;
						}
					}
				}
			}

			> p {
				margin-top: $space-md;
			}

			.success {
				input {
					@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(2px), $color-success-500);
				}

				+ .input-group__error-text p {
					color: $color-success-500;
				}
			}

			#forgot-password,
			#to-login-page,
			#skip-auth-setup,
			.resend-2fa,
			.back-to-login,
			.t-and-c-policy-checkbox > label {
				font-family: $body-font-family;
				font-size: 14px;
				max-width: unset;
				font-weight: 600;

				button {
					background: none;
					border: none;
					font-family: $body-font-family;
					font-size: 14px;
					font-weight: 700;
					color: $color-secondary-500;

					&:hover {
						cursor: pointer;
						text-decoration: underline;
					}
				}
			}

			.verify-additional-details {
				margin-top: 10px;
				width: 100%;

				.back-to-login {
					float: right;
				}
			}

			.t-and-c-policy-checkbox {
				span a {
					color: $color-secondary-500;
				}
			}

			.submit-register {
				margin-bottom: $spacing-24;
			}
		}

		@media only screen and (min-width: 600px) {
			width: convert-rem(512px);
		}

		.submit-register,
		.confirm-registration,
		.login-link,
		.back-to-account-create,
		.enable-2fa,
		.submit-2fa {
			width: 100%;
			margin: $spacing-16 0 0;
		}
	}
}

.register {
	align-items: flex-start;

	a {
		text-decoration: none;
		cursor: pointer;
		min-height: convert-rem(20px);
		margin-bottom: $space-lg;
	}

	h5 {
		margin-top: 0;
	}

	.input-group {
		width: 100%;
	}
}

.login__forgot-password {
	display: flex;

	&:hover,
	&:active {
		border-bottom: none;
	}
}

.password-input-icon {
	display: flex;
	position: absolute;
	padding-top: 20px;
	right: 1rem;
	height: 100%;
	align-items: center;

	&:hover {
		opacity: 40%;
		@include transform-timing($quick-animation-time);
	}
}

.password-container {
	grid-column-start: 1;
	position: relative;
	input {
		width: 100%;
	}

	::-ms-reveal {
		display: none;
	}
}
// % protected region % [Change login, register and reset password styles here] end

// % protected region % [Add additional login, register and reset password styles here] off begin
// % protected region % [Add additional login, register and reset password styles here] end