/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Timelines - graph view
-------------------------------- */

/*Shared map and mixin*/

//create array to loop through - to change colour of shape just change `color-suppot-{color}` to colour preference

// % protected region % [Add shape definitions here] off begin
$admin-color-shapes: ("diamond" : $admin-color-support-green, "circle" : $admin-color-support-red, "square" : $admin-color-support-purple, "default" : $admin-color-primary);
// % protected region % [Add shape definitions here] end


// % protected region % [Change timelines view styles here] off begin
@mixin apply-graph-shape($shape) {
	@if $shape==diamond {
		.item__amount {
			&:after {
				transform: rotate(45deg);
			}
		}
	}

	@else if $shape==circle {
		.item__amount {
			&:after {
				border-radius: 50%;
			}
		}
	}

	@else if $shape==square {
		.item__amount {
			&:after {
				border-radius: unset;
			}
		}
	}

	@else if $shape==default {
		.item__amount {
			&:after {
				border-radius: unset;
			}
		}
	}

	@else {
		@error "This mixin supports sizes you used "+$size;
	}
}

@mixin apply-sidebar-shape($shape) {
	@if $shape==diamond {
		&:before {
			transform: rotate(45deg);
		}

	}

	@else if $shape==circle {
		&:before {
			border-radius: 50%;
		}

	}

	@else if $shape==square {
		&:before {
			border-radius: unset;
		}
	}

	@else if $shape==default {
		&:before {
			border-radius: unset;
		}
	}

	@else {
		@error "This mixin supports sizes you used "+$size;
	}
}

//Mixin and variable for timelines view animations
$admin-views: view-left, left-view, view-right, right-view, view-zoom, zoom-view, view-reset;

@mixin apply-view-animation($view) {
	@if $view=="view-left" {
		.date__item {
			@include slideInLeft($admin-slow-animation-time);
		}
	}

	@else if $view=="left-view" {
		.date__item {
			@include slideInLeft2($admin-slow-animation-time);
		}
	}

	@else if $view=="view-right" {
		.date__item {
			@include slideInRight($admin-slow-animation-time);
		}
	}

	@else if $view=="right-view" {
		.date__item {
			@include slideInRight2($admin-slow-animation-time);
		}
	}

	@else if $view=="zoom-view" {
		.date__item {
			@include zoomIn($admin-slow-animation-time);
		}
	}

	@else if $view=="view-zoom" {
		.date__item {
			@include zoomIn2($admin-slow-animation-time);
		}
	}

	@else if $view=="view-reset" {
		.date__item {
			@include zoomIn3($admin-slow-animation-time);
		}
	}

	@else {
		@error "This mixin supports view classes you used "+$view;
	}
}


/*END MIXIN*/

/* start styling of timelines graph view */
.timelines__view {
	display: flex;
	align-items: center;
	min-height: 35rem;
	overflow: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	//chevron butons
	.icon-chevron-left, .icon-chevron-right {
		max-width: $admin-space-lg;
		height: 100%;
		align-items: center;
		background-color: transparent;

		&:before,
		&:after {
			font-size: $admin-text-lg;
			color: $admin-grey-4;
		}

		&:hover,
		&:active {

			&:before,
			&:after {
				color: $admin-color-primary;
				font-size: 2.5rem;
				@include transform-timing($admin-animation-time);
			}
		}
	}

	//timeline view - with the date, the lines and the shapes
	.timelines {
		flex-grow: 1;
		margin-top: auto;
		border-top: convert-rem(1.5px) solid $admin-black;

		//bottom date section
		.timelines__dates {
			display: flex;
			justify-content: space-between;

			//Animation calls in mixins and class names - refer to mixins above
			@each $view in $admin-views {
				&.#{$view} {
					@include apply-view-animation(#{$view});
				}
			}

			//each date
			.date__item {
				text-align: center;
				display: flex;
				flex-direction: column;
				position: relative;
				align-items: center;
				padding-top: $admin-space-xs;
				background: none;
				border: none;
				@include zoomIn($admin-animation-time);

				&:after {
					content: '';
					height: convert-rem(400px);
					width: 1px;
					background-color: $admin-grey-1;
					display: flex;
					position: absolute;
					z-index: 0;
					bottom: 100%;
				}
				.date {
					min-width: convert-rem(100px);
				}

				.timelines__item, .timelines__items {
					position: absolute;
					bottom: calc(100% - 3px);
					display: flex;
					flex-direction: column;
					align-items: center;
					cursor: pointer;
                    z-index: 2;

					//apply default styles for item amount
					.item__amount {
						max-height: 2rem;
						max-width: 2rem;
						text-align: center;
						margin: auto;
						color: $admin-white;
						align-items: center;
						justify-content: center;
						position: relative;
						display: flex;

						span {
							z-index: 1;
						}

						&:after {
							content: '';
							display: block;
							background-color: $admin-black;
							display: inline-block;
							position: absolute;
							width: 1.7rem;
							height: 1.7rem;
							z-index: 0;
						}
					}

					.item-line {
						height: convert-rem(180px);
						width: 1px;
						background-color: $admin-black;
						display: flex;
						margin: auto;
					}

					.bottom-circle {
						width: convert-rem(10px);
						height: convert-rem(10px);
						border-radius: 50%;
						background-color: $admin-black;
					}

					@each $shape,
					$color in $admin-color-shapes {
						&.#{$shape} {
							@include apply-graph-shape(#{$shape});

							.item__amount {
								&:after {
									background-color: $color;
								}
								&:hover, &:active {
									color: $admin-black;
									@include transform-timing($admin-animation-time);
								}
							}

							.bottom-circle {
								background-color: $color;
							}
						}
					}

					//override other shapes for TODAY

					//for today item
					&.today {
						.item-line {
							height: convert-rem(220px);
							width: 2px;
						}

						.item__amount {
							span {
								font-weight: $admin-text-bold;
								color: $admin-black;
							}

							&:after {
								display: none;
							}
						}

						.bottom-circle {
							border-radius: none;
							background-color: $admin-black;
						}
					}
				}
			}
		}
	}
}

/* end styling of timelines graph view */

/* timelines graph sidebar view */
.sidebar__graph-view {
	margin-bottom: 0;
	padding: $admin-space-xs $admin-space-md;

	//apply shape styles to both list
	ul {
		list-style: none;
		padding-left: .2rem;

		li {
			display: grid;
			grid-template-areas: "shape date" "shape info";
			grid-template-columns: auto 1fr;
			margin-bottom: $admin-space-xs;
			grid-area: info;
			align-items: center;

			span {
				font-weight: bold;
				grid-area: date;
			}

			&:before {
				content: '';
				width: 1rem;
				height: 1rem;
				text-align: center;
				margin-right: $admin-space-xs;
				color: $admin-white;
				align-items: center;
				justify-content: center;
				position: relative;
				display: flex;
				grid-area: shape;
			}

			@each $shape,
			$color in $admin-color-shapes {
				&.#{$shape} {
					@include apply-sidebar-shape(#{$shape});

					&:before {
						background-color: $color;
					}
				}
			}
		}
	}

	.sidebar__recent-activity {
		ul {
			li {
				align-items: flex-start;

				&:before {
					margin-top: .3rem;
				}
			}
		}
	}

	.sidebar__legend {
		h5 {
			margin-top: $admin-space-xs;
		}
	}
}

/* end timelines graph sidebar view */

/*ITEM INFO STYLING - this is the box when you click on an item (shape) */

.timelines__item, .timelines__items {
	.item__info {
		position: absolute;
		right: $admin-space-xs;
		bottom: 105%;
		width: convert-rem(400px);
		background-color: $admin-grey-10;
		color: $admin-white;
		padding: $admin-space-xs $admin-space-sm;
		text-align: left;
		box-shadow: convert-rem(0px) convert-rem(2px) convert-rem(5px) convert-rem(-2px) $admin-black;
		cursor: default;
		@include fadeIn($admin-slow-animation-time);

		&.left {
			left: $admin-space-xs;
		}

		&.right {
			right: $admin-space-xs;
		}

		.icon-cross {
			align-items: center;
			background-color: transparent;
			padding: 0;
			margin-left: auto;

			&:before {
				color: $admin-grey-2;
				font-size: $admin-text-md;
				margin: 0;
			}

			&:hover,
			&:active {
				&:before {
					color: $admin-white;
				}
			}
		}

		//Each timeline event information
		.info {
			margin-bottom: $admin-space-sm;
			.info__top-section {
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: $admin-grey-2;

				h5 {
					margin-bottom: 0;
					font-size: $admin-text-md;
				}
			}

			.info__middle-section {
				display: flex;
				margin: $admin-space-xxs 0;
				justify-content: space-between;
				background: none;
				border: none;
				color: white;
				min-width: 100%;
				padding: 0;

				p {
					margin-bottom: 0;
					font-size: .9rem;
				}
			}
		}

		//Bottom section of zoom more or view more
		.bottom-section {
			display: flex;
			justify-content: space-between;
			color: $admin-grey-2;
		}
	}
}

/*END ITEM INFO STYLING*/

// % protected region % [Change timelines view styles here] end
// % protected region % [Add additional timelines view styles here] off begin
// % protected region % [Add additional timelines view styles here] end