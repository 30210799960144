/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Forms
-------------------------------- */
/*
Forms styles done here such as submissions
*/

// % protected region % [Change form submission styles here] off begin
/* Form submission */
.form-submission {
	h3 {
		font-size: $text-md;
		text-decoration: underline;
		font-weight: $text-regular;
		margin: $space-md;
	}

	.question__error {
		color: $color-support-red;
		padding: 0;
	}
}

/* Form List View */
.form-list {
	list-style: none;

	.form-list-item {
		list-style: none;
		list-style-position: outside;
		&:before {
			content: "\2022";
			color: get-contrasting-text-color($background);
			font-weight: bold;
			display: inline-block;
			width: 1em;
			margin-left: -1rem;
		}
	}
}

/* Form section */
.form__section .form__question-container {
	padding-left: $space-md;
}

/* Form statement */
.form-statement {
	&--important {
		padding: 1rem;
		margin-bottom: 1rem;
		background-color: $background;
		color: get-contrasting-text-color($background);
		.question__content {
			color: $background;
		}
	}
	&--light {
		padding: 1rem;
		margin-bottom: 1rem;
		background-color: lighten-color($background);
		color: get-contrasting-text-color($background);

		.question__content {
			color: get-contrasting-text-color($background);
		}
	}
}


// % protected region % [Change form submission styles here] end

// % protected region % [Add additional form styles here] off begin
// % protected region % [Add additional form styles here] end
