@use "sass:math";

.crud--PermissionSchemeEntity {
	height: 100%;
	.permission-scheme-entity-form-crud-container {
		background-color: $admin-page-background;
		height: inherit;
		padding-top: $spacing-64;

		.permission-scheme-entity-form {
			padding: $spacing-32;
			max-width: 1024px;
			min-width: math.div(1024px, 2);
			background-color: $white;
			border-radius: $spacing-4;
			margin: auto;

			.spinner-container {
				position: static;
			}

			.permission-container {
				min-width: 600px;
				display: grid;
				gap: $spacing-24;
				grid-template-columns: 1fr 1fr;

				.permission-scheme-name-input {
					margin: 0px;
					padding: 0px;

					label {
						color: $admin-grey-10;
						line-height: $spacing-16;
						font-size: calc($spacing-12 + $spacing-2);
					}
					input {
						width: 400px;
						height: 50px;
						margin-bottom: $spacing-8;
					}
				}

				.footer > button:not(:first-child) {
					margin-left: $spacing-16
				}

				> .permission-grid-element {
					grid-column: span 1;
					width: 100%;

					button:first-of-type {
						margin-right: $spacing-16;
					} 

					&:first-child, &:last-child {
						grid-column: span 2;
					}

					&:not(:first-child):not(:last-child) {
						padding: $spacing-16;
						border: 1px solid $admin-grey-3;
					}

					h6 {
						margin: 0px 0px $spacing-16 0px;
					}

					.input-group__checkbox {
						display: inline-block;
						min-width: 50%;
						white-space: nowrap;
						margin: $spacing-12 0px;

						input {
							min-width: 20px;
							width: 20px;
							height: 20px;
							border: 2px solid $color-secondary-500;
							display: inline-block;

							&::after {
								color: $color-secondary-500;
							}
						}

						label {
							margin: 0px $spacing-8;
							display: inline-block;
						}
					}

				}
			}
		}
	}
}