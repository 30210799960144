﻿.engine-control-hint {
	display: flex;
	flex-direction: column;
	gap: $spacing-8;
	position: absolute;
	bottom: $spacing-24;
	right: $spacing-24;
	padding: $spacing-6;
	opacity: 0.25;
	border-radius: $spacing-2;
	transition: opacity 0.2s ease-in-out;

	&:hover {
		opacity: 1;
	}

	.control-action {
		display: flex;
		align-items: center;
		position: relative;

		.mouse-icon {
			height: 32px;
			width: 32px;
		}

		.modifier, .text {
			font-weight: 600;
			font-size: 14px;
			margin: 0 0 0 $spacing-4;
		}

		.modifier {
			position: absolute;
			left: -100%;
			width: 100%;
			margin: 0 $spacing-4 0 0;
			text-align: right;
		}
	}
}
