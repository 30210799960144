@import '../elements/inputs/tooltip.scss';

.system-setup-wizard-page {
	grid-area: body;
	height: 100%;
	width: 100%;
	position: relative;
	background-color: $page-background;
	overflow-y: auto;

	padding: $spacing-64 0;
	display: grid;
	grid-template-columns: auto minmax(700px, 1024px) auto;
	grid-template-areas: ". content .";

	.system-wizard {
		position: relative;
		z-index: 1;
		min-width: 700px;
		grid-area: content;

		&__content {
			background: $white;
			border-radius: 4px;
			padding: $spacing-32 $spacing-32 $spacing-16;

			.system-wizard__header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: $spacing-32;

				h4 {
					margin: 0;
				}

				button {
					padding: 0;
					font-size: 1.5rem;
				}
			}

			.system-wizard__step-content {
				margin: $spacing-32 0;

				#step-2-content {
					.node-type-group {
						.node-type {
							display: grid;
							grid-template-areas:
								"name edit-icon"
								"description edit-icon";
							align-items: center;
							width: 100%;
							border: 1px solid $color-neutral-form-border-300;
							border-radius: 2px;
							padding: $spacing-16 $spacing-24;
							margin-bottom: $spacing-16;

							&__name {
								grid-area: name;
								margin: 0;
							}

							&__description {
								grid-area: description;
								margin: 0;
								color: $color-neutral-secondary-text-700;
							}

							&__edit-icon {
								grid-area: edit-icon;
								justify-self: end;

								button {
									padding: 0;

									&:after {
										font-size: 1.5rem;
										color: $color-neutral-primary-text-900;
									}
								}
							}
						}
					}
				}
			}

			.step-button-group {
				justify-content: space-between;
				margin-top: $spacing-24;
				margin-bottom: $spacing-8;

				.btn {
					margin: 0;
				}
			}
		}
	}
}