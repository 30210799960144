.engine-3-content {
	color: $white;
	height: 100%;
	display: flex;
	flex-direction: row;

	.engine-sidebar {
		max-width: calc(100vw - 650px);
		.resizeable {
			max-width: inherit;
		}
		z-index: 10;
	}

	.timeline {
		width: 100%;
	}
}
