.sidebar-layout {
	position: absolute;
	z-index: 10;
	display: flex;
	flex-direction: row;
	height: inherit;
	width: inherit;
	pointer-events: none;

	.engine-control-hint {
		right: unset;
		left: -104px;
	}

	.resizeable-flex-item-container {
		flex-grow: 1;
		max-width: 100vw;
	}
}

.resizeable-flex-item-container {
	.resizeable {
		z-index: 10;
		pointer-events: all;
		transition-duration: 300ms;
		transition-property: transform;
		transition-timing-function: ease-out;

		.resize-handle {
			height: 100%;
		}

		&.left {
			z-index: 1;
			transform: translate(0px, 0px);

			&.hidden {
				transform: translate(calc(-100% - 10px), 0px);
			}
		}

		&.right {
			margin-left: auto;
			margin-right: 0;
			transform: translate(0px, 0px);

			&.hidden {
				transform: translate(calc(100% + 10px), 0px);
			}
		}
	}
}

.resize-handle {
	transition: opacity 0.15s ease-in-out;
	position: relative;
	z-index: 30;
	opacity: 0;

	&:hover, &:active {
		background-color: $color-primary;
		opacity: 0.8;
	}
}

.sidebar-container {
	z-index: 1;
	height: 100%;
	width: 100%;
	color: $white;
	background-color: $grey-7;
	top: 0;
	user-select: none;
	position: absolute;
	overflow-y: auto;
}

.node-details {
	display: flex;
	flex-direction: column;

	&__header {
		top: 0px;
		z-index: 1;
		position: sticky;
		display: grid;
		grid-template-areas:
			"node-name actions"
			"node-type actions";
		min-height: 89px;
		padding: $spacing-16;

		.node-name {
			grid-area: node-name;
			margin: 0 0 $spacing-8 0;
			align-self: end;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.node-type {
			grid-area: node-type;
			align-self: center;
			margin: 0;
			line-height: 1.2;
		}

		.node-actions {
			grid-area: actions;
			justify-self: end;
			align-self: start;
			margin-top: $spacing-2;
			font-size: 1.2rem;

			& > .menu {
				padding: $spacing-4
			}
		}

		@include node-level-background('level-0', $color-level0, $color-level0-text);
		@include node-level-background('level-1', $color-level1, $color-level1-text);
		@include node-level-background('level-2', $color-level2, $color-level2-text);
		@include node-level-background('level-3', $color-level3, $color-level3-text);
		@include node-level-background('level-4', $color-level4, $white);
	}

	&__item {
		display: grid;
		grid-template-areas:
			"title action"
			"content content";
		padding: $spacing-16;
		margin: $spacing-12 $spacing-12 0px $spacing-12;

		&:last-child {
			margin-bottom: $spacing-12;
		}

		background-color: $color-neutral-disabled-text-600;
		align-items: center;
		border-radius: 2px;
		cursor: pointer;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

		&:hover {
			background-color: $color-neutral-800;
		}

		.node-details__item-title {
			margin: 0;
			grid-area: title;
		}

		.node-details__item-action {
			grid-area: action;
			justify-self: end;

			.btn {
				height: unset;
				padding: 0;

				&:after {
					color: white;
				}
			}
		}

		.node-details__item-content {
			grid-area: content;
			margin-top: $spacing-12;

			.carver-score-preview {
				display: flex;
				justify-content: space-between;

				&__item {
					color: $color-neutral-200;
					display: flex;
					flex-direction: column;
				}
			}

			#node-image {
				.upload__drag-area {
					background-color: $color-neutral-disabled-bg-500;
				}
			}
		}

		&.node-image-upload {
			.node-details__item-action {
				.icon {
					margin: 0;
					font-size: 1.1rem;
				}
			}

			.node-details__item-content {
				.upload {
					.preview {
						height: auto;
						border: none;

						img {
							height: auto;
						}

						.icon-bin-delete {
							display: none;
						}
					}
				}
			}
		}

		&.node-notes {
			.node-details__item-content {
				.node-notes__editor {
					.ql-toolbar {
						padding: $spacing-2;

						.ql-formats {
							margin: 0;
						}

						.ql-stroke,
						.ql-fill {
							stroke: $white;
						}

						.ql-active {
							.ql-stroke,
							.ql-fill {
								stroke: $color-text-dark;
							}

							background-color: $white;
						}
					}

					.ql-container {
						.ql-editor {
							p {
								line-height: 1.125rem;
							}

							a {
								color: #2FA0FF;
							}
						}
					}
				}

				.btn-group {
					.btn {
						margin-right: 0;
						margin-bottom: 0;
					}
				}
			}
		}

		&.node-location {
			.input-group {
				margin-bottom: 0;

				label,
				input,
				.dropdown__container,
				.dropdown__container:hover,
				.dropdown__container > .menu,
				.dropdown > .text > div,
				.dropdown.icon {
					color: white !important;
					border-width: 1px;
					border-color: white !important;
				}

				input::placeholder {
					color: $grey-2;
				}

				input {
					@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(1px), $white);
					height: 3rem;
				}
			}

			.input-group__error-text > p {
				color: #ff7878;
			}

			.address-system-selector {
				margin-bottom: 0;

				.dropdown__container {
					margin-bottom: 0;
				}
			}

			.btn-group {
				.btn {
					margin-right: 0;
					margin-bottom: 0;
					padding: $spacing-8 $spacing-16;
				}
			}

			.address-autocomplete-input-wrapper {
				#address-autocomplete-input {
					background: transparent;
					border-radius: 2px;
					border: none;
					@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(1px), $white);
				}

				.help-text {
					margin-bottom: 0
				}
			}
		}
	}
}

.configuration-modal.edit-carver-score-modal {
	width: 575px;
	max-width: 575px;
	top: 10%;
	max-height: 85%;
	overflow: visible;

	.configuration-modal__header {
		position: sticky;
		top: 0;
		z-index: 1;
	}

	.edit-carver-score-modal-content {
		padding: $spacing-16;

		.input-group__dropdown {
			margin: 0;

			.dropdown__container {
				height: unset;
				min-height: 3rem;
				border-width: 1px;
				margin: 0 0 0.5rem 0;

				&.active, &.active:hover, &:hover, &:focus, .menu {
					border-width: 1px !important;
				}

				.menu {
					margin: 0 -1px;
					min-width: 100%;
					max-height: min-content;
					box-shadow: 0 0 50px 20px #000000AA;

					> .item {
						padding: calc(0.5 * 1rem) calc(1 * 1rem) !important;
					}

					.selected {
						background-color: rgba(0, 0, 0, 0.2);
					}
				}

				.metric-option {
					width: 100%;
					display: grid;
					grid-template-areas:
						"value name"
						"value description";
					align-items: center;
					justify-content: start;
					margin: $spacing-8 0;

					&__value {
						grid-area: value;
						color: $color-neutral-disabled-bg-500;
						font-size: 1.7rem;
						font-weight: 500;
						margin-right: $spacing-12;
					}

					&__name {
						font-size: 1rem;
						grid-area: name;
						margin: 0;
						color: $color-neutral-primary-text-900;
					}

					&__description {
						font-size: 1rem;
						grid-area: description;
						color: $color-neutral-secondary-text-700;
					}
				}
			}
		}
	}
}

.configuration-modal.node-attribute-modal {
	display: grid;
	height: 570px;
	max-height: unset;
	overflow: hidden;
	grid-template-rows: min-content min-content 1fr min-content;
	user-select: none;

	.configuration-modal__header {
		display: grid;
		grid-template-areas:
			"title actions"
			"search search";

		.modal-title {
			grid-area: title;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.configuration-modal__header__actions {
			grid-area: actions;
			justify-self: end;

			.btn {
				padding: 0 0 0 $spacing-16;
			}

			.icon-reply {
				background-color: white;
				border-radius: 50%;
				height: 32px;
				width: 32px;
				color: $color-black;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0;
				box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);

				&:before {
					transform: rotate(90deg) scaleX(-1);
					font-size: 1.25rem;
				}

				&:hover {
					background-color: #FFF099;
				}
			}
		}

		.attribute-search {
			grid-area: search;

			.input-group {
				color: $white;
				position: relative;
				margin: 0;

				@include lightning-icon(before, search);

				&:before {
					position: absolute;
					top: 1.7rem;
					left: 0.5rem;
					z-index: 1;
				}

				label {
					color: $white;
				}

				input {
					height: 40px;
					color: $white;
					padding: $space-xs $space-xs $space-xs 2.5rem;
					margin: 0;
					line-height: 1.9;
					@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(1px), $white);

					&:active,
					&:focus {

						&::-webkit-input-placeholder,
						&:-moz-placeholder,
						&:-ms-input-placeholder {
							opacity: 0;
							@include fadeOut($animation-time);
						}
					}
				}

				input::placeholder {
					color: $grey-3;
				}
			}
		}
	}

	.add-attribute-current-level-button {
		border-radius: 0;
		justify-content: space-between;
		padding: $spacing-8 $spacing-16;
	}

	.configuration-modal__body {
		overflow: auto;
		height: 100%;
	}

	.configuration-modal__footer {
		.add-attribute-group-button {
			width: 100%;
			border-radius: 0;
			height: 40px;

			&:before {
				font-size: 1.3rem;
				margin: 0 $spacing-4 0 0;
			}
		}

		.up-delete-actions {
			background-color: $color-neutral-800;
			height: 48px;
			padding: 0 $spacing-8;

			.btn {
				color: $white;
				padding: 0 $spacing-8;
				text-transform: none;

				&:before,
				&:after {
					margin: 0;
				}

				&.icon-bin-full {
					&:before {
						margin-right: $spacing-16;
					}
				}
			}
		}
	}
}
