﻿.minimal-accordion {
	display: flex;
	flex-direction: column;
	border: none;

	>.minimal-accordion__item {
		.title {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			p {
				margin: 0;
			}

			.indicator:before {
				@include transform-timing-specific(transform, $quick-animation-time);
			}
			&.active .indicator:before {
				transform: rotate(180deg);
			}

			& ~ .content.item__content {
				padding-top: 0;
			}
		}

		.content {
			display: block;
			overflow-y: hidden;
			max-height: 0px !important;
			transition: max-height 0.1s ease-out;

			&.active {
				// max height just needs to be larger than anythig realistically ever required and can be safely increased need be
				max-height: 1000px !important;
				transition: max-height 0.3s ease-in;
			}
		}
	}
}
