.edit-attributes-accordion {
	border: convert-rem(1px) solid $grey-2;
	border-top: none;
	padding: $spacing-16;

	&__title-wrapper {
		display: grid;
		grid-template-columns: 1fr min-content;
		align-items: center;

		.title {
			overflow: hidden;

			.attribute-group-checkbox {
				margin: 0 !important;
				align-items: center;

				label {
					max-width: unset;
					width: 90%;

					h5 {
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
						line-height: inherit;
					}
				}
			}

			h5 {
				display: inline-block;
				margin: 0;
				line-height: 0;
			}

			button {
				padding: 0;
				height: unset;
				margin: 0;

				&:before {
					margin-right: $spacing-8;
				}
			}
		}
	}

	&:first-child {
		border-top: convert-rem(1px) solid $grey-2;
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
	}

	&:last-child {
		border-bottom-left-radius: 2px;
		border-bottom-right-radius: 2px;
	}

	.accordion__info {
		font-size: $base-font-size;
		background-color: $white;

		&--expanded {
			min-height: unset;
		}

		.attribute-children {
			display: flex;
			flex-wrap: wrap;

			.attribute-children-checkbox {
				min-width: 300px;

				label {
					align-items: flex-start;

					.edit-child-attribute-button {
						padding: 0;
						color: $color-text-dark;
						min-height: 0;
						height: min-content;

						&:after {
							font-size: 1.1rem;
						}
					}
				}
			}
		}
	}

	.btn-group {
		width: max-content;

		button {
			height: unset;
			padding: 0;
			margin: 0;
		}
	}
}