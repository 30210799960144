/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

//Custom elements
@import "elements/inputs/custom-bot-inputs.scss";
@import "elements/inputs/custom-bot-select.scss";
@import "elements/inputs/custom-bot-button.scss";

//Custom components
@import "components/alerts/custom-bot-alerts.scss";

// % protected region % [Add additional imports content here] on begin
::-webkit-scrollbar {
	width: $spacing-8;
	height: $spacing-8;
}
::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: $color-neutral-form-border-300;
	border-radius: $spacing-4;
}

::-webkit-scrollbar-thumb:hover {
	background: $grey-5;
}
// % protected region % [Add additional imports content here] end
