/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Colour Variables
-------------------------------- */
/* _colors.scss is dependent on this file using variables that are changable in projects
*/


// % protected region % [Set colours, accent colours, and greyscale colours] on begin
/**
	CUSTOM COLOR VARIABLES
**/

// primary
$color-primary-50: #FFFBE6;
$color-primary-100: #FFF8CC;
$color-primary-200: #FFF099;
$color-primary-300: #FFE966;
$color-primary-400: #FFE133;
$color-primary-500: #FFDA00; // default
$color-primary-600: #CCAE00;
$color-primary-700: #998300;
$color-primary-800: #665700;
$color-primary-900: #332C00;

// secondary
$color-secondary-50: #E6EDF3;
$color-secondary-100: #CCDBE7;
$color-secondary-200: #99B6CF;
$color-secondary-300: #6692B7;
$color-secondary-400: #336D9F;
$color-secondary-500: #004987; // default
$color-secondary-600: #003A6C;
$color-secondary-700: #002C51;
$color-secondary-800: #001D36;
$color-secondary-900: #000F1B;

// tertiary
$color-tertiary-50: #E6EDF3;
$color-tertiary-100: #ECF4F9;
$color-tertiary-200: #DAE9F3;
$color-tertiary-300: #C7DDEE;
$color-tertiary-400: #B5D2E8;
$color-tertiary-500: #A2C7E2; // default
$color-tertiary-600: #829FB5;
$color-tertiary-700: #617788;
$color-tertiary-800: #41505A;
$color-tertiary-900: #20282D;

// success
$color-success-50: #E6F2EC;
$color-success-100: #CCE4D9;
$color-success-200: #99C9B2;
$color-success-300: #66AE8C;
$color-success-400: #339365;
$color-success-500: #00783F; // default
$color-success-600: #006032;
$color-success-700: #004826;
$color-success-800: #003019;
$color-success-900: #00180D;

// info
$color-info-50: #E6E9EF;
$color-info-100: #CCD2DE;
$color-info-200: #99A6BE;
$color-info-300: #66799D;
$color-info-400: #334D7D;
$color-info-500: #00205C; // default
$color-info-600: #001A4A;
$color-info-700: #001337;
$color-info-800: #000D25;
$color-info-900: #000612;

// warning
$color-warning-50: #FDF0E6;
$color-warning-100: #FBE2CC;
$color-warning-200: #F8C49A;
$color-warning-300: #F4A767;
$color-warning-400: #F18935;
$color-warning-500: #ED6C02; // default
$color-warning-600: #BE5602;
$color-warning-700: #8E4101;
$color-warning-800: #5F2B01;
$color-warning-900: #2F1600;

// danger
$color-danger-50: #F4E8E7;
$color-danger-100: #EAD1CE;
$color-danger-200: #D5A39E;
$color-danger-300: #BF756D;
$color-danger-400: #BE3223;
$color-danger-500: #95190C; // default
$color-danger-600: #77140A;
$color-danger-700: #590F07;
$color-danger-800: #3C0A05;
$color-danger-900: #1E0502;

// neutral
$color-neutral-50: #E7E8E9;
$color-neutral-hover-100: #CED1D3; // hover states
$color-neutral-200: #B6B9BD;
$color-neutral-form-border-300: #9EA2A7; // form border
$color-neutral-form-border-hover-400: #868B92; //form border hover
$color-neutral-disabled-bg-500: #6D747C; // disabled bg
$color-neutral-disabled-text-600: #555D66; // disabled text
$color-neutral-secondary-text-700: #3D4550; // secondary text
$color-neutral-800: #242E3A;
$color-neutral-primary-text-900: #0C1724; // primary text

// common
$color-black: #231F20;
$color-white: #FFFFFF;

// levels
$color-level1: #C5CCFA;
$color-level1-stroke: #9FA7CC;
$color-level1-text: #5561AC;
$color-level1-completed: #5561AC;
$color-level1-stroke-completed: #0B1D6E;
$color-level2: #B5D898;
$color-level2-stroke: #5E7940;
$color-level2-text: #4E7528;
$color-level2-completed: #5E7940;
$color-level2-stroke-completed: #405528;
$color-level3: #FB8518;
$color-level3-stroke: #87650C;
$color-level3-text: #7B3B00;
$color-level4: #F0515B;
$color-level4-stroke: #652C32;

/* Main colors */
/* -------------------------------------------------*/
/* ----------Pick your brand colours here ----------*/
/* -------------------------------------------------*/
$admin-color-primary: rgb(255, 218, 0); // #FFDA00 // KBR yellow
$admin-color-secondary: rgb(0, 72, 135); // #004987; // Dark blue
$admin-color-brand-01: rgb(163, 200, 227); // #A2C7E2 // Charcoal
$admin-color-brand-02: rgb(213, 213, 213) !default; // #D5D5D5; // Grey
$admin-color-brand-03: rgb(244, 244, 244) !default; //#F4F4F4 // Light grey

// Accent Colours
$admin-color-support-red: rgba(246, 70, 79, 1) !default; // #E84D38 // Support color red
$admin-color-support-green: rgba(35, 215, 158, 1) !default; // #38CC7D // Support color green
$admin-color-support-blue: rgba(76, 98, 240, 1) !default; // #009FDD // Support color blue
$admin-color-support-yellow: rgba(244, 220, 62, 1) !default; // #FBC200 // Support color yellow
$admin-color-support-orange: rgba(251, 164, 65, 1) !default; // #F27600 // Support color orange
$admin-color-support-purple: rgba(91, 15, 211, 1) !default; // #8623DF // Support color purple

// Shades - generated using chroma.js
// 12 steps https://gka.github.io/palettes/#colors=#121230,#f5f5f5%7Csteps=11%7Cbez=1%7CcoL=1
$admin-black: $color-black;
$admin-grey-10: $color-neutral-primary-text-900; // primary text
$admin-grey-8: $color-neutral-800;
$admin-grey-7: $color-neutral-secondary-text-700; // secondary text
$admin-grey-6: $color-neutral-disabled-text-600; // disabled text
$admin-grey-5: $color-neutral-disabled-bg-500; // disabled background
$admin-grey-4: $color-neutral-form-border-hover-400; // form border hover
$admin-grey-3: $color-neutral-form-border-300; // form border
$admin-grey-2: $color-neutral-200;
$admin-grey-1: $color-neutral-hover-100; // hover states
$admin-grey-0: $color-neutral-50;
$admin-white: $color-white;

$admin-page-background: $admin-grey-10;

//Text color - do not use. Use for function
$admin-color-text-dark: $color-primary;
$admin-color-text-light: $white;


/* Colour Variables */
$admin-color-shades: 10% !default;
$admin-color-alpha: 0.8% !default;
$admin-color-shadow: 0.2% !default;

/* Colour Variations */
$admin-color-primary-light: lighten($admin-color-primary, $admin-color-shades) !default;
$admin-color-primary-dark: darken($admin-color-primary, $admin-color-shades) !default;
$admin-color-primary-bg: rgba($admin-color-primary, $admin-color-alpha) !default;

$admin-color-secondary-light: lighten($admin-color-secondary, $admin-color-shades) !default;
$admin-color-secondary-dark: darken($admin-color-secondary, $admin-color-shades) !default;
$admin-color-secondary-bg: rgba($admin-color-secondary, $admin-color-alpha) !default;

$admin-color-brand-01-light: lighten($admin-color-brand-01, $admin-color-shades) !default;
$admin-color-brand-01-dark: darken($admin-color-brand-01, $admin-color-shades) !default;
$admin-color-brand-01-bg: rgba($admin-color-brand-01, $admin-color-alpha) !default;

$admin-color-brand-02-light: lighten($admin-color-brand-02, $admin-color-shades) !default;
$admin-color-brand-02-dark: darken($admin-color-brand-02, $admin-color-shades) !default;
$admin-color-brand-02-bg: rgba($admin-color-brand-02, $admin-color-alpha) !default;

/* Support colors */
$admin-color-support-red-light: lighten($admin-color-support-red, $admin-color-shades) !default;
$admin-color-support-red-dark: darken($admin-color-support-red, $admin-color-shades) !default;
$admin-color-support-red-bg: rgba($admin-color-support-red, $admin-color-alpha) !default;

$admin-color-support-green-light: lighten($admin-color-support-green, $admin-color-shades) !default;
$admin-color-support-green-dark: darken($admin-color-support-green, $admin-color-shades) !default;
$admin-color-support-green-bg: rgba($admin-color-support-green, $admin-color-alpha) !default;

$admin-color-support-blue-light: lighten($admin-color-support-blue, $admin-color-shades) !default;
$admin-color-support-blue-dark: darken($admin-color-support-blue, $admin-color-shades) !default;
$admin-color-support-blue-bg: rgba($admin-color-support-blue, $admin-color-alpha) !default;

$admin-color-support-yellow-light: lighten($admin-color-support-yellow, $admin-color-shades) !default;
$admin-color-support-yellow-dark: darken($admin-color-support-yellow, $admin-color-shades) !default;
$admin-color-support-yellow-bg: rgba($admin-color-support-yellow, $admin-color-alpha) !default;

$admin-color-support-orange-light: lighten($admin-color-support-orange, $admin-color-shades) !default;
$admin-color-support-orange-dark: darken($admin-color-support-orange, $admin-color-shades) !default;
$admin-color-support-orange-bg: rgba($admin-color-support-orange, $admin-color-alpha) !default;

$admin-color-support-purple-light: lighten($admin-color-support-purple, $admin-color-shades) !default;
$admin-color-support-purple-dark: darken($admin-color-support-purple, $admin-color-shades) !default;
$admin-color-support-purple-bg: rgba($admin-color-support-purple, $admin-color-alpha) !default;
// % protected region % [Set colours, accent colours, and greyscale colours] end
// % protected region % [Add additional colours here] off begin
// % protected region % [Add additional colours here] end