.stepper {
	user-select: none;
	.stepper-header {
		display: flex;
		justify-content: space-between;

		.divider {
			flex-grow: 1;
			border-style: none;
			border-top: 1px solid $color-neutral-disabled-bg-500;
			margin: auto 20px;
		}

		.step {
			display: flex;
			align-items: center;
			position: relative;

			&__label p,
			&__order p {
				margin: unset;
			}

			&__label {
				color: $color-neutral-secondary-text-700;
				margin-left: $spacing-12;
				transition: all ease-in 0.15s;
			}

			&__order {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 36px;
				height: 36px;
				border-radius: 50%;
				background: $color-neutral-form-border-300;
				color: $color-neutral-secondary-text-700;
				transition: all ease-in 0.15s;
			}

			&:last-of-type + .divider {
				display: none;
			}

			.step__order {
				h6 {
					line-height: 0;
				}
			}

			&.active {
				.step__order {
					color: $white;
					background: $color-secondary-500;
				}

				.step__label {
					color: $color-secondary-500;
				}
			}

			&.completed {
				.step__order {
					color: $white;
					background: $color-secondary-300;
				}

				.step__label {
					color: $color-secondary-300;
				}
			}
		}
	}
}