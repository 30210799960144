.metric-toggle {
	margin-left: auto;
	border: none;
	border-radius: 56px;
	color: $white;
	height: inherit;
	min-width: $spacing-48;
	padding: 0 initial;
	@include metric-score-color();

	> h6 {
		margin: 0;
		display: inline;
	}
}