.analysis-navigator {
	min-width: 200px;
	margin: $spacing-12;

	h5 {
		padding-bottom: 0;
	}

	.dropdown-indent {
		padding-left: $spacing-32;
	}

	.dropdown-item {
		padding: $spacing-6 $spacing-4;
		font-size: $base-font-size;
		display: flex;
		column-gap: $spacing-8;
		align-items: center;
		border-radius: $spacing-2;

		&:first-child {
			padding-top: 0;
		}

		span {
			cursor: pointer;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		@mixin node-checkbox(
			$node-level,
			$unchecked-border,
			$unchecked-background,
			$check-color
		) {
			input[type="checkbox"].#{$node-level} {
				background-color: $unchecked-background;
				border: convert-rem(2px) solid $unchecked-border;

				&::after {
					color: $check-color;
					background-color: $unchecked-border;
				}
			}
		}

		@include node-checkbox('level-0', $grey-6, $grey-8, $white);
		@include node-checkbox('level-1', $color-level1-completed, $color-level1-stroke-completed, $white);
		@include node-checkbox('level-2', $color-level2-completed, $color-level2-stroke-completed, $white);
		@include node-checkbox('level-3', $color-level3-stroke, #4D3804, $white);
		@include node-checkbox('level-4', $color-level4, $color-level4-stroke, $white);

		&:hover {
			filter: brightness(0.8);
		}

		.indicator {
			display: inline-block;
			margin: 0;
			width: $spacing-24;
			height: $spacing-24;
			font-size: $spacing-12;
			line-height: $spacing-12;

			&.icon-gap {
				min-width: $spacing-24;
			}

			&:before {
				@include transform-timing-specific(transform, $quick-animation-time);
				color: $grey-3;
				width: $spacing-12;
				height: $spacing-12;
				margin: $spacing-6;
				transform-origin: center right;
			}

			&.active:before {
				transform: rotate(90deg);
			}
		}
	}

	.dropdown-children {
		transition: max-height 1s ease-out;
		max-height: 1000px; // must be greater than any height realistically attained

		&.hidden {
			max-height: 0;
			transition: max-height 0.15s ease-out;
			overflow: hidden;
		}
	}
}