
/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Icons
-------------------------------- */
/*
These are the lightning icon variables list
https://medium.com/codyhouse/create-your-design-system-part-5-icons-594f39cfb1b
*/

/* To use an icon icon($position: before, $icon: false, $styles: true) */
/*https://jaydenseric.com/blog/fun-with-sass-and-font-icons*/

/* Current provided icons : https://cdn.lampbot.ai/lightning-icons/latest/lightning-icons.css */

/*
Your project is currently using lightning icons, you can choose to use your own icons by appending to the map below.
Make sure you have created and imported your custom fonts.
*/

// % protected region % [Change lightning icons here] on begin
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");

$lightning-icons: (
	"3-way-split-bottom": "\e900",
	"3-way-split-left": "\e901",
	"3-way-split-right": "\e902",
	"3-way-split-top": "\e903",
	"academy": "\e80c",
	"access-database": "\e905",
	"accessiblity": "\e914",
	"accessories": "\e907",
	"act": "\e908",
	"actions": "\e909",
	"activity": "\e90a",
	"agile": "\e90c",
	"ai": "\e90d",
	"air-con": "\e90e",
	"airplay": "\e90f",
	"alert": "\e626",
	"align-centre": "\e011",
	"align-justify": "\f773",
	"align-left": "\e011",
	"align-right": "\e010",
	"alpha": "\e915",
	"alpha-2": "\e916",
	"anchor": "\f1cd",
	"android": "\e859",
	"anzac": "\e919",
	"aperture": "\e91a",
	"api": "\e91b",
	"app": "\e91c",
	"apple": "\e110",
	"aquila": "\e91e",
	"arc-170": "\e91f",
	"architect": "\e920",
	"archivable": "\e921",
	"armour": "\e922",
	"army": "\ea3f",
	"arrow-down": "\e5db",
	"arrow-left": "\e5c4",
	"arrow-left-down": "\e928",
	"arrow-left-up": "\e929",
	"arrow-right": "\e5c8",
	"arrow-right-down": "\e92c",
	"arrow-right-up": "\f8ce",
	"arrow-up": "\e5d8",
	"artboard": "\e40a",
	"article": "\ef42",
	"at-sign": "\e934",
	"attributes": "\e935",
	"aurora": "\e936",
	"auto-layout": "\e938",
	"avocado": "\e939",
	"award": "\f6a0",
	"axe": "\e93b",
	"background-story": "\e93c",
	"backlog": "\e93d",
	"backstab": "\e93e",
	"backwards-circle": "\e93f",
	"bacon": "\e940",
	"badge": "\f612",
	"balance": "\eaf6",
	"banner": "\e944",
	"barbarian": "\e945",
	"bard": "\e946",
	"bath": "\ea41",
	"battery": "\e1a4",
	"battery-charge-1": "\e949",
	"battery-charge-2": "\e94a",
	"battery-charge-3": "\e94b",
	"battery-charging": "\e94c",
	"battleboard": "\e3ec",
	"bdg": "\ea8e",
	"beach": "\e94f",
	"bean": "\e950",
	"bed": "\efdf",
	"beer": "\e952",
	"behaviours": "\e953",
	"bell": "\e954",
	"bell-off": "\e955",
	"bell-snooze": "\e956",
	"belt": "\e957",
	"beta": "\e958",
	"beta-2": "\e959",
	"bicycle": "\e95a",
	"bin-2": "\e95b",
	"bin-delete": "\e872",
	"bin-empty": "\e95d",
	"bin-full": "\e872",
	"blame": "\e960",
	"bluetooth": "\e1a7",
	"board": "\eb7f",
	"bold": "\e963",
	"bomb": "\f685",
	"book": "\e666",
	"bookmark": "\e866",
	"book-open": "\e866",
	"boolean": "\e9f5",
	"borg": "\e969",
	"bot": "\e96a",
	"bot-chat": "\e96b",
	"bot-delete": "\e96c",
	"bot-new": "\e96d",
	"bot-remove": "\e96e",
	"bow": "\e96f",
	"box": "\f720",
	"bread": "\e973",
	"breakfast": "\e974",
	"brief": "\e873",
	"briefcase": "\e8f9",
	"brightness": "\e3ab",
	"bring-forward": "\e979",
	"bring-to-front": "\e97a",
	"brush": "\e97c",
	"btn": "\e97d",
	"bucket": "\f8b4",
	"buff-the-worm": "\e97f",
	"bug": "\e868",
	"buildings": "\e982",
	"bus": "\e530",
	"cable": "\efe6",
	"calculate": "\e24a",
	"calender": "\ebcc",
	"camera": "\e412",
	"cancel": "\e98b",
	"captain": "\e98e",
	"car": "\e531",
	"cards": "\e8eb",
	"care-bed": "\e991",
	"care-equipment": "\e992",
	"car-park": "\e993",
	"cart": "\e8cc",
	"cast": "\e307",
	"pets": "\ebb9",
	"categories": "\e997",
	"change": "\e998",
	"change-product": "\e999",
	"channel": "\e99a",
	"chart-bar-1": "\e26b",
	"chart-line": "\eb66",
	"chart-pie": "\e6c4",
	"chats": "\e0b7",
	"check": "\e5ca",
	"check-box": "\e834",
	"check-circle": "\e837",
	"check-circle-2": "\e9a5",
	"check-out": "\e9a6",
	"chevron-down": "\e5cf",
	"chevron-left": "\e5cb",
	"chevron-right": "\e5cc",
	"chevrons-down": "\e9ac",
	"chevrons-left": "\e9ad",
	"chevrons-right": "\e9ae",
	"chevrons-up": "\e9af",
	"chevron-up": "\e5ce",
	"chicken": "\e9b1",
	"child": "\eb41",
	"children": "\e9b3",
	"christmas": "\e8b1",
	"circle": "\ef4a",
	"circle-add": "\e9b7",
	"circle-bot": "\e9b8",
	"circle-delete": "\e9b9",
	"circle-fill": "\e9ba",
	"circle-minus": "\e9bb",
	"cis": "\e9bc",
	"clean": "\f21d",
	"cleric": "\e9be",
	"clipboard": "\e862",
	"clock": "\e924",
	"clone": "\e9c1",
	"cloth": "\e9c2",
	"cloud": "\e2bd",
	"cloud-code": "\e9c4",
	"cloud-download": "\e2c0",
	"cloud-drizzle": "\e2c0",
	"cloud-off": "\e2c1",
	"cloud-power": "\ebdb",
	"cloud-rain": "\f176",
	"cloud-snow": "\e2cd",
	"cloud-upload": "\e2c3",
	"clear-all": "\e0b8",
	"cocktail": "\e9cc",
	"code": "\ead3",
	"coffee": "\efef",
	"cold": "\eb3b",
	"collapse-right": "\e9d3",
	"collaspe": "\e9d4",
	"collaspe-left": "\e9d5",
	"column": "\e9d6",
	"command": "\e9d8",
	"community": "\e9d9",
	"compass": "\e9da",
	"component": "\e9db",
	"conference": "\e9dc",
	"conflict": "\e9dd",
	"connection": "\efe6",
	"constellation": "\e9e0",
	"contrast": "\e9e1",
	"controller": "\e9e2",
	"copy": "\e14d",
	"corner-down-left": "\e9e4",
	"corner-down-right": "\f1e4",
	"corner-left-down": "\f1e5",
	"corner-left-up": "\e9e7",
	"corner-right-down": "\e9e8",
	"corner-right-up": "\e9e9",
	"corner-up-left": "\e9ea",
	"corner-up-right": "\e9eb",
	"cow": "\e9ec",
	"credit-card": "\e870",
	"crop": "\e9ee",
	"crosshair": "\e1b7",
	"crud": "\e896",
	"csharpbot": "\e9f2",
	"css": "\e9f3",
	"cube": "\f720",
	"cube-add": "\e9f5",
	"cube-delete": "\e9f6",
	"cube-minus": "\e9f7",
	"cut": "\e14e",
	"cygnas": "\e9f9",
	"d4": "\e9fa",
	"d6": "\e9fb",
	"d8": "\e9fc",
	"d10": "\e9fd",
	"d12": "\e9fe",
	"d20": "\e9ff",
	"d20-dot": "\ea00",
	"d100": "\ea01",
	"dagger": "\ea02",
	"dark-matter": "\ea04",
	"dashboard": "\e871",
	"database": "\f20e",
	"date-time": "\ea08",
	"dead": "\f89a",
	"decimal": "\f82c",
	"delete": "\ea0b",
	"delete-column": "\ea0c",
	"delete-row": "\ea0d",
	"deliverable": "\e558",
	"deploy": "\ea10",
	"desk": "\ea12",
	"desk-stand": "\ea13",
	"desktop": "\e30c",
	"dessert": "\f00b",
	"details": "\e3c8",
	"development": "\ea17",
	"devices": "\e1b1",
	"diamond": "\ead5",
	"dice": "\ea1a",
	"dinner": "\ea1b",
	"disc": "\e610",
	"disconnect": "\ea1d",
	"display-all": "\ea1e",
	"divide": "\ea1f",
	"dog": "\f149",
	"download": "\f090",
	"dragon": "\ea22",
	"draw": "\f603",
	"draws": "\e746",
	"dress": "\e13e",
	"drip": "\ea26",
	"dropbox": "\e1a1",
	"druid": "\ea28",
	"dual-screen": "\ea29",
	"duplicate": "\ea2b",
	"easter": "\e799",
	"edge": "\ea2d",
	"edit": "\e3c9",
	"edit-box": "\ea2f",
	"edit-line": "\ea30",
	"egg": "\ea31",
	"element": "\ea32",
	"emoji": "\ea33",
	"empire": "\ea34",
	"enhancement": "\e663",
	"entity": "\e9fe",
	"enum": "\ea37",
	"environments": "\e875",
	"epic": "\ea39",
	"error": "\e000",
	"ethernet": "\ea3b",
	"exclamation": "\f22f",
	"expand": "\ea3e",
	"expand-2": "\ea3f",
	"export": "\ea40",
	"eye-dropper": "\ea41",
	"eye-track": "\e8f4",
	"fan": "\f168",
	"fastfoward": "\e01f",
	"feature": "\ea47",
	"feed": "\ea48",
	"feedback": "\e0bf",
	"female": "\e590",
	"female-female": "\ea4b",
	"female-male": "\e63d",
	"fetch": "\ea4d",
	"field-trial": "\f04c",
	"fighter": "\ea4f",
	"file": "\e873",
	"file-add": "\e89c",
	"file-copy": "\e173",
	"file-delete": "\f74f",
	"film": "\ea5b",
	"filter": "\ef4f",
	"finger-print": "\e90d",
	"finish": "\ea5e",
	"fire": "\ea43",
	"fireworks": "\f685",
	"fish": "\f1ea",
	"flag": "\e153",
	"flex": "\ea63",
	"flow": "\ea64",
	"flow-break": "\ea65",
	"flow-connect": "\ea66",
	"flow-connect-type": "\ea67",
	"flow-data": "\ea68",
	"flow-directional": "\ea69",
	"flower": "\ea6a",
	"flow-user": "\ea6b",
	"flow-web": "\ea6c",
	"fob": "\ea6d",
	"folder": "\ea6e",
	"folder-add": "\ea6f",
	"folder-copy": "\ea70",
	"folder-delete": "\ea71",
	"folder-open": "\ea72",
	"folder-remove": "\ea73",
	"food": "\ea64",
	"football": "\ea2f",
	"footer": "\ea76",
	"forms": "\f009",
	"fosnax": "\ea78",
	"foward-circle": "\ea7a",
	"full-moon": "\ea7c",
	"gift": "\e8b1",
	"git-branch": "\ea7f",
	"git-commit": "\ea80",
	"github": "\ea81",
	"gitlab": "\ea82",
	"git-merge": "\ea83",
	"git-pull-request": "\ea84",
	"glass": "\ea85",
	"globe": "\e80b",
	"golf": "\eb45",
	"google-drive": "\ea88",
	"grab-that-moon": "\ea89",
	"grid": "\e9b0",
	"gutter": "\ea8d",
	"gym": "\f6e6",
	"hammer": "\ea59",
	"hand-grab": "\ea90",
	"hand-ok": "\ea91",
	"hand-open": "\e769",
	"hand-point": "\ebb9",
	"hand-wave": "\ea94",
	"hard-drive": "\f80e",
	"harmony": "\ea96",
	"hashtag": "\ea97",
	"hat": "\ea98",
	"header": "\ea99",
	"heading": "\ea9a",
	"headphones": "\f01f",
	"health": "\e1d5",
	"heart": "\e87d",
	"heart-broken": "\eac2",
	"helicopter": "\eaa2",
	"helmet": "\eaa3",
	"help": "\e887",
	"hexagon": "\eb39",
	"hexagon-add": "\eaa6",
	"hexagon-delete": "\eaa7",
	"hexagon-fill": "\eaa8",
	"hexagon-minus": "\eaa9",
	"hidden": "\eaaa",
	"hike": "\eaab",
	"historical": "\eaac",
	"hoist": "\eaad",
	"home": "\e88a",
	"honey": "\eaaf",
	"horizontal": "\eab0",
	"horizontal-split": "\eab1",
	"hospital": "\e4b9",
	"hot": "\e4b9",
	"hourglass": "\ea5b",
	"hourglass-empty": "\e88b",
	"hr": "\f106",
	"html": "\eb7e",
	"ice-cream": "\eab8",
	"idea": "\e0f0",
	"img": "\e3f4",
	"import": "\eabc",
	"inbox": "\e156",
	"indent-in": "\eabe",
	"indent-out": "\eabf",
	"information": "\e88e",
	"input": "\e890",
	"insert-column": "\eac2",
	"insert-row": "\eac3",
	"integer": "\eb8d",
	"investigate": "\f106",
	"iot": "\eac7",
	"issue": "\e638",
	"italics": "\eac9",
	"iteration": "\eaca",
	"jake": "\eacb",
	"jam": "\eacc",
	"jay": "\eacd",
	"jedi": "\eace",
	"jenkins": "\eacf",
	"jira": "\ead1",
	"jordie": "\ead2",
	"josh": "\ead3",
	"js": "\ead4",
	"juice": "\ead5",
	"key": "\e73c",
	"keyboard": "\e312",
	"knight": "\ead9",
	"lampbot": "\eada",
	"landscape": "\eadb",
	"laptop": "\eadc",
	"launch": "\eadd",
	"layers": "\e53b",
	"layout": "\eadf",
	"lead": "\eae0",
	"leaf": "\ea35",
	"left-right": "\eae2",
	"lens": "\eae3",
	"leo": "\eae4",
	"lesson": "\e54b",
	"letter": "\e554",
	"library": "\e02f",
	"library-item": "\e431",
	"life-buoy": "\ef73",
	"light-bulb": "\e0f0",
	"lighthouse": "\eaeb",
	"lightning": "\ec1c",
	"lightning-off": "\e3e6",
	"lightsaber": "\eaee",
	"line-height": "\eaef",
	"link-1": "\e157",
	"link-2": "\e178",
	"link-broken": "\e16f",
	"list": "\eaf6",
	"literals": "\eaf7",
	"loader": "\eaf8",
	"location-map": "\eaf9",
	"location-pin": "\e0c8",
	"lock": "\e898",
	"lock-open": "\eafc",
	"login": "\ea77",
	"logout": "\e9ba",
	"lolly": "\eaff",
	"look": "\f1c5",
	"look-off": "\eb01",
	"loudspeaker": "\ef49",
	"lounge": "\e16b",
	"lunch": "\f1f4",
	"lyra": "\eb05",
	"magic": "\eb06",
	"mail": "\e158",
	"mail-open": "\e151",
	"maintance": "\ea3d",
	"male": "\e58e",
	"male-female": "\eb0b",
	"male-male": "\eb0c",
	"man": "\e4eb",
	"management": "\f02e",
	"man-child": "\eb0f",
	"many-to-many": "\eb10",
	"map": "\e55b",
	"margin": "\eb12",
	"marketing": "\eb13",
	"maximise": "\e56b",
	"maxminise-2": "\eb15",
	"meat": "\eb16",
	"meeting": "\f233",
	"menu": "\e5d2",
	"message": "\e625",
	"message-circle": "\ef58",
	"mic": "\e029",
	"mic-off": "\e02b",
	"micronode": "\eb1d",
	"microphone": "\ef5a",
	"microwave": "\f204",
	"mikaela": "\eb20",
	"minimise": "\eb21",
	"minimise-2": "\eb22",
	"minus": "\eb23",
	"minus-circle": "\eb24",
	"misson": "\e539",
	"mobile": "\e325",
	"model": "\eb27",
	"money": "\ef63",
	"monk": "\eb29",
	"moon": "\f159",
	"more-horizontal": "\e5d3",
	"more-vertical": "\e5d4",
	"motorbike": "\eb2d",
	"mountain": "\e3f7",
	"mouse": "\e323",
	"move": "\e89f",
	"music": "\e405",
	"naveen": "\eb32",
	"navigation": "\e988",
	"nebula": "\eb35",
	"network": "\eb36",
	"nicola": "\eb38",
	"note": "\eb3a",
	"nova": "\eb3b",
	"nsw": "\eb3c",
	"nt": "\eb3d",
	"nuts": "\eb3e",
	"nx-01": "\eb3f",
	"oceania": "\eb40",
	"olive-oil": "\eb41",
	"ol-list": "\eb42",
	"onboarding": "\eb43",
	"one-to-many": "\eb44",
	"one-to-one": "\eb45",
	"open-left": "\eb46",
	"open-right": "\eb47",
	"opera": "\eb48",
	"order": "\eb49",
	"package": "\e48f",
	"pacman": "\eb4c",
	"paint-brush": "\e3ae",
	"paladin": "\eb4e",
	"palette": "\e40a",
	"pants": "\e273",
	"paperclip": "\e226",
	"paper-plane": "\e163",
	"park": "\ea63",
	"party": "\eb54",
	"paste": "\eb55",
	"path": "\eacd",
	"pattern-library": "\eb57",
	"pause": "\eb58",
	"pause-circle": "\eb59",
	"peanut": "\eb5a",
	"pen": "\e3c9",
	"person": "\e7fd",
	"person-add": "\eb5d",
	"person-check": "\eb5e",
	"person-delete": "\eb5f",
	"person-group": "\eb60",
	"person-remove": "\eb61",
	"phoenix": "\eb62",
	"phone": "\e325",
	"phonebooth": "\eb64",
	"phone-call": "\eb65",
	"phone-forwarded": "\eb66",
	"phone-incoming": "\eb67",
	"phone-missed": "\eb68",
	"phone-off": "\eb69",
	"phone-outgoing": "\eb6a",
	"php": "\eb6b",
	"picto": "\eb6c",
	"pig": "\eb6d",
	"pin": "\f10d",
	"pirate": "\eb71",
	"pizza": "\eb72",
	"plan": "\eb73",
	"plane": "\e539",
	"plant": "\eb75",
	"platform": "\eb77",
	"play": "\e037",
	"play-circle": "\eb79",
	"plugin": "\eb7a",
	"plus": "\e145",
	"plus-circle": "\e147",
	"pocket": "\eb7d",
	"pointer": "\eb7e",
	"poll": "\eb7f",
	"pool": "\eb48",
	"poster": "\eb81",
	"post-it": "\f1fc",
	"power": "\f8c7",
	"pray": "\f838",
	"presentation": "\eaf0",
	"printer": "\e8ad",
	"problem": "\eb87",
	"process": "\eb88",
	"product": "\eb89",
	"production": "\eb8a",
	"production-2": "\eb8b",
	"application": "\eb8c",
	"application-new": "\eb8d",
	"demote": "",
	"promote": "\eb8e",
	"properties": "\e0ee",
	"prototype": "\eb90",
	"pullup-banner": "\eb91",
	"puzzle": "\eb93",
	"pyxis": "\eb94",
	"qld": "\eb95",
	"qualification": "\eb96",
	"question": "\eb8b",
	"quill": "\eb98",
	"quote": "\e244",
	"quote-end": "\eb9a",
	"radio": "\e03e",
	"railroad": "\eb9c",
	"ramp": "\eb9d",
	"ranger": "\eb9e",
	"rank": "\eb9f",
	"reader": "\eba0",
	"rearrange": "\e16d",
	"rebels": "\eba2",
	"recycle": "\eba3",
	"redo": "\e15a",
	"refresh-ccw": "\e5d5",
	"refresh-cw": "\e042",
	"remote": "\e83e",
	"reorder": "\eba8",
	"repair": "\eba9",
	"repeat": "\e040",
	"requirements": "\ebab",
	"reset": "\ebac",
	"resolve": "\ebad",
	"results": "\f0c5",
	"reublic": "\ebaf",
	"rewind": "\ebb0",
	"rhiannon": "\ebb1",
	"ribs": "\ebb2",
	"rides": "\ebb3",
	"river": "\f084",
	"roadmap": "\ebb5",
	"rocket": "\eba5",
	"rogue": "\ebb7",
	"rogue-two": "\ebb8",
	"room": "\eb4f",
	"root": "\e0b6",
	"rotate-ccw": "\ebbb",
	"rotate-ccw-2": "\ebbc",
	"rotate-cw": "\ebbd",
	"rotate-cw-2": "\ebbe",
	"row": "\ebbf",
	"rss": "\ebc0",
	"running": "\e566",
	"sa": "\ebc2",
	"sad": "\e811",
	"sail-ship": "\e502",
	"sale": "\ebc5",
	"salt": "\ebc6",
	"satellite": "\ebc7",
	"save": "\e161",
	"scan": "\ebc9",
	"scissors": "\f08b",
	"scooter": "\ebcb",
	"scope": "\ebcc",
	"screen-share": "\ebcd",
	"search": "\e8b6",
	"secret": "\ebcf",
	"security-camera": "\ebf4",
	"select-column": "\ebd1",
	"select-row": "\ebd2",
	"send-backwards": "\ebd3",
	"send-to-back": "\ebd4",
	"seo": "\ebd5",
	"sequence": "\ebd6",
	"serena": "\ebd7",
	"server": "\ebd8",
	"session": "\eb4f",
	"settings": "\e8b8",
	"share": "\e80d",
	"shared-library": "\e54b",
	"shield": "\ebdf",
	"shield-off": "\ebe0",
	"ship": "\e532",
	"shirt": "\ebe2",
	"shoe": "\ebe3",
	"shop": "\ea12",
	"shorts": "\ebe5",
	"shower": "\efdd",
	"shrink": "\ebe7",
	"shuffle": "\e043",
	"sidebar": "\f114",
	"sith": "\ebec",
	"skate": "\ebed",
	"ski": "\e509",
	"skip-backward": "\ebef",
	"skip-backwards-circle": "\ebf0",
	"skip-forward": "\ebf1",
	"skip-foward-circle": "\ebf2",
	"skirt": "\ebf3",
	"slash": "\ebf5",
	"slide": "\ebf7",
	"sliders-1": "\ebf8",
	"sliders-2": "\ebf9",
	"smaller": "\ebfa",
	"snack": "\ebfb",
	"snow": "\ebfc",
	"soccer": "\ebfd",
	"social": "\ebfe",
	"sorcerer": "\ebff",
	"sound-off": "\e04e",
	"sound-on": "\e050",
	"source": "\e12d",
	"source-code": "\eb8e",
	"south-america": "\ec04",
	"southern-cross": "\ec05",
	"space": "\eb9b",
	"spacebar": "\ec07",
	"space-invader": "\ec08",
	"spanner": "\e869",
	"speaker": "\e32d",
	"spear": "\ec0b",
	"sports": "\ec0c",
	"spreadsheet": "\e228",
	"sprint": "\f81f",
	"square-bot": "\ec10",
	"square-fill": "\ec11",
	"square-x": "\ec12",
	"stall": "\e8d1",
	"star": "\e838",
	"stop-circle": "\ec16",
	"stories": "\ec17",
	"strike": "\ec18",
	"string": "\ec19",
	"structure": "\ec1a",
	"subscript": "\ec1b",
	"suggestion": "\ec1c",
	"sun": "\e81a",
	"sunglasses": "\f6ee",
	"sunrise": "\e1c6",
	"sunset": "\f874",
	"superscript": "\ec21",
	"sushi": "\ec22",
	"sword": "\ec23",
	"table": "\ec24",
	"tablet": "\ec25",
	"tag": "\e892",
	"tag-add": "\ec27",
	"tag-remove": "\ec28",
	"target": "\e1b7",
	"tas": "\ec2b",
	"task": "\f075",
	"tea": "\ec2d",
	"teach": "\ec2e",
	"team": "\ec2f",
	"terminal": "\eb8e",
	"tessa": "\ec31",
	"tests": "\e105",
	"test-tubes": "\e103",
	"text-larger": "\ec34",
	"text-smaller": "\ec35",
	"text-to-speech": "\ec36",
	"thermometer": "\ec37",
	"thumbs-down": "\e8db",
	"thumbs-up": "\e8dc",
	"tile": "\ec3c",
	"timeline": "\e922",
	"toggle-left": "\e9f5",
	"toogle-right": "\e9f6",
	"torch": "\f00b",
	"touch": "\e913",
	"town-hall": "\e7f1",
	"toy": "\e332",
	"train": "\e570",
	"tree": "\ea63",
	"trello": "\ec46",
	"trend-down": "\e8e3",
	"trend-up": "\e8e5",
	"triangle": "\e86b",
	"triangle-add": "\ec4a",
	"triangle-bot": "\ec4b",
	"triangle-delete": "\ec4c",
	"triangle-down": "\ec4d",
	"triangle-fill": "\ec4e",
	"triangle-minus": "\ec4f",
	"triangle-up": "\ec50",
	"trophy": "\ec51",
	"truck": "\ec52",
	"tv": "\e333",
	"uat": "\ec56",
	"umbrella": "\f1ad",
	"uncollaspe": "\ec58",
	"underline": "\ec59",
	"underwear": "\ec5a",
	"undo": "\e166",
	"up-down": "\ec5c",
	"upload": "\e2c6",
	"usb": "\e1e0",
	"vegetables": "\ec63",
	"versions": "\ec64",
	"vertical-split": "\ec65",
	"vic": "\ec66",
	"video": "\f06a",
	"video-controller": "\ec68",
	"video-off": "\ec69",
	"view": "\ec6a",
	"voicemail": "\e0d9",
	"void": "\ec6e",
	"volcano": "\ec6f",
	"volume": "\ec70",
	"volume-1": "\ec71",
	"volume-2": "\e050",
	"volume-x": "\e04e",
	"vr": "\ec74",
	"vulture-droid": "\ec75",
	"wa": "\ec76",
	"walk": "\e536",
	"wallet": "\ec78",
	"warlock": "\ec79",
	"warning": "\e645",
	"watch": "\e334",
	"water": "\e798",
	"waterfall": "\ec7d",
	"wave": "\e176",
	"web-cam": "\f7a6",
	"wheat": "\ec80",
	"wheel": "\ec81",
	"wheel-menu": "\ec82",
	"whip": "\ec83",
	"whiteboard": "\ec84",
	"wifi": "\e63e",
	"wifi-off": "\e648",
	"wind": "\efd8",
	"windows": "\ec88",
	"wine": "\ec89",
	"wizard": "\ec8a",
	"woman": "\e13e",
	"woman-child": "\ec8c",
	"workflow": "\f845",
	"work-item": "\ea67",
	"wormhole": "\ec91",
	"cross": "\e5cd",
	"x-circle": "\e5c9",
	"youtube": "\f85a",
	"zoom-in": "\ec96",
	"zoom-out": "\ec97",
	"reply": "\e15e",
	"360": "\e577",
);
$lightning-icon-name: "Material Symbols Outlined";
// % protected region % [Change lightning icons here] end

// % protected region % [Customise icon application here] off begin
@each $name,
$char in $lightning-icons {
	.icon-#{$name} {
		&:before,
		&:after {
			content: $char;
		}
	}
}
// % protected region % [Customise icon application here] end

// % protected region % [Add your custom icons here] off begin
$icons-custom: (
);

$icon-custom-name: "";
// % protected region % [Add your custom icons here] end

// % protected region % [Add custom icon spacing here] on begin
*[class*='icon-'] {
	&:before, &:after {
		font-family: $lightning-icon-name;
		font-weight: normal;
		font-size: 1.5rem;
	}

	//Left and top icons should be before classes
	&.icon-top,
	&.icon-left {
		&:after {
			display: none;
		}
	}

	&.icon-left {
		&:before {
			margin-right: $space-sm;
		}
	}

	&.icon-top {
		display: block;

		&:before {
			display: block;
			margin-bottom: $space-xs;
		}
	}

	//Right and bottom icons should be after classes
	&.icon-bottom,
	&.icon-right {
		&:before {
			display: none;
		}
	}

	&.icon-right {
		&:after {
			margin-left: $space-sm;
		}
	}

	&.icon-bottom {
		display: block;

		&:after {
			display: block;
			margin-top: $space-xs;
		}
	}

	&.icon-only {
		display: block;

		&:before {
			display: block;
		}
		&:after{
			display: none;
		}
	}
}
// % protected region % [Add custom icon spacing here] end

// % protected region % [Customise custom icon application here] off begin
@each $name,
$char in $icons-custom {
	.icon-#{$name} {
		&:after,
		&:before {
			content: $char;
			font-family: $icon-custom-name;
		}
	}
}
// % protected region % [Customise custom icon application here] end

// % protected region % [Add additional icons here] off begin
// % protected region % [Add additional icons here] end
