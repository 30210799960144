.attribute-radio-button {
	margin: $spacing-12 $spacing-16;
	justify-content: space-between;

	label {
		margin: 0;

		input {
			margin-right: $spacing-12;
		}
	}

	.btn {
		padding: 0;
		height: inherit;

		&.add-attribute-button:after {
			color: $color-danger-400;
		}

		&.navigate-down-button {
			color: $color-neutral-disabled-text-600;
		}
	}
}