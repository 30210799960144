@use "sass:math";
$header-height: calc($spacing-24 + $spacing-32 + $spacing-8);
$header-height-half: math.div($header-height, 2);
$min-content-height: $spacing-48;
$list-border-radius: $radius-4;

.minimal-list {
	background: $page-background;
	border-radius: $list-border-radius;
	position: relative;
	width: 100%;
	height: 100%;
	max-height: inherit;
	display: flex;
	flex-flow: column;

	::-webkit-scrollbar {
		width: $spacing-12;
	}
	::-webkit-scrollbar-track {
		background: transparent;
	}

	::-webkit-scrollbar-thumb {
		background: $color-neutral-form-border-300;
		border-radius: $spacing-4;
	}

	::-webkit-scrollbar-thumb:hover {
		background: $grey-5;
	}

	.heading {
		height: $header-height;
		padding-bottom: 39px;
		flex: 0 1 auto;
		color: $white;
		display: inline;
		display: flex;
		justify-content: space-between;

		h5 {
			line-height: 1.4em;
		}

		& button {
			padding: 0px;
			height: 100%;

			&::before {
				font-size: $spacing-24;
				height: $spacing-24;
				width: $spacing-24;
				color: $white;
				margin: 0px;
			}
		}
	}

	.list-radius {
		flex: 1 1 auto;
		background: $white;
		border-radius: $list-border-radius;
		position: relative;
		max-height: calc(100% - $header-height);

		.spinner-container {
			position: relative;
			width: 100%;
			height: 100%;
		}

		.list-content {
			position: relative;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			font-size: 1rem;

			.list-item {
				height: $spacing-48;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding-right: $spacing-12;
				padding-left: $spacing-12;
				white-space: nowrap;
				cursor: pointer;

				.list-label {
					width: 100%;
					max-width: 245px;
					overflow: hidden;
					text-overflow: ellipsis;
					height: $spacing-48;
					line-height: $spacing-48;
					vertical-align: middle;
				}

				> .btn {
					background-color: transparent;
					color: $color-neutral-primary-text-900;
					position: relative;
					right: 0px;
					padding-right: 0px;
				}
			}
		}
	}
}
