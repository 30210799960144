.engine-1-page {
	height: 100%;
	width: 100%;
	min-width: 600px;
	grid-area: body;
	position: relative;

	.background-image {
		height: inherit;
		width: inherit;
		position: absolute;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 500px;
		filter: opacity(0.25);
		z-index: -5;
	}
}