
/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

// % protected region % [Modify the html and body scss here] on begin
html, body {
	overflow-y: hidden;
}
html {
	font-size: 16px;
}
// % protected region % [Modify the html and body scss here] end

// % protected region % [Modify the router outlet scss here] off begin
router-outlet {
	display: none;
}
// % protected region % [Modify the router outlet scss here] end

.body-container {
	display: grid;
	grid-template-areas: "adminbar" "contentwrapper";
	grid-template-rows: auto 1fr;
	position: absolute;
	height: 100vh; /* Fallback for browsers that do not support Custom Properties */
	height: calc(var(--vh, 1vh) * 100);
	left: 0;
	right: 0;

	>.admin {
		overflow: hidden;
		display: grid;
		width: 100%;
		grid-template-areas: "navhorizontal navhorizontal" "navvertical body";
		grid-template-columns: minmax(0, auto) 1fr;
		grid-template-rows: auto 1fr;
		grid-area: contentwrapper;

		//Mobile responsive
		@media (max-width: 1000px) {
			grid-template-areas: "navvertical body" "navhorizontal navhorizontal";
			grid-template-rows: 1fr auto;
			grid-template-columns: minmax(0, auto) 1fr;
			position: relative;
		}
	}

	// % protected region % [Change project layout here] on begin
	>.frontend {
		overflow: hidden;
		display: grid;
		width: 100%;
		grid-template-areas: "navhorizontal navhorizontal" "navvertical body";
		grid-template-columns: minmax(0, auto) 1fr;
		grid-template-rows: auto 1fr;
		grid-area: contentwrapper;
	}
	.kbr-logo-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		row-gap: 0.25rem;
		position: fixed;
		bottom: 2rem;
		left: 2rem;
		color: white;
		user-select: none;
		img {
			height: 4rem;
			width: auto;
		}
	}
}
// % protected region % [Change project layout here] end