.force-graph-render-target {
	width: 100%;
	height: 15px;
}

.force-graph {
	height: 100%;
	width: 100%;
	position: relative;

	.scene-tooltip {
		display: flex;
		justify-content: space-between;
		align-items: center;
		user-select: none;

		font-family: $body-font-family;
		color: $color-neutral-primary-text-900;
		background-color: $white;
		font-size: $text-md;

		padding: 0 $spacing-24;
		font-weight: bold;

		border: 1px solid $grey-3;
		border-radius: $spacing-2;

		width: 280px;
		min-height: 56px;

		&:empty {
			display: none;
		}
	}

	.rotate-graph-btn {
		position: absolute;
		z-index: 5;
		left: $spacing-16;
		bottom: $spacing-16;
		width: $spacing-32;
		height: $spacing-32;
		padding: 0;
		line-height: 1;

		&.btn--primary {
			&::before {
				margin: auto;
				width: $spacing-24;
				height: $spacing-24;
			}

			&:not([disabled]) {
				@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(2px), $color-primary);
				color: $color-primary;
			}
			&:disabled {
				@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(2px), $color-primary);
				color: $color-primary;
			}
			&:hover {
				color: black;
			}
			&:active {
				filter: brightness(85%);
			}

			&.rotating {
				background-color: $color-primary;
				color: black;
			}
		}
	}
}
