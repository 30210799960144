.node-details-popup-list {
	top: 0px;
	right: 0px;
	width: 300px;
	position: absolute;
	overflow-y: auto;
	transition: all;
	max-height: 100%;

	> .node-details-popup-card {
		margin: $spacing-10;
	}
}

.node-details-popup-card {
	background-color: $grey-6;
	z-index: 5;
	box-shadow: 0px $spacing-2 $spacing-2 rgba(0, 0, 0, 0.15);
	border-radius: $spacing-2;
	padding: $spacing-8 $spacing-16;
	display: flex;
	flex-direction: column;

	.file-preview > .upload__image.preview {
		border: 0px;
		width: 100%;
		height: 110px;
		max-height: 300px;
		background-color: transparent;
		margin-bottom: $spacing-4;

		.image {
			height: inherit;
			max-height: inherit;
			background-color: inherit;

			img {
				height: inherit;
				max-height: inherit;
				object-fit: cover;
				border-radius: $spacing-4;
			}
		}
	}

	.header {
		color: $white;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		min-height: $spacing-24;

		h6 {
			white-space: pre-wrap;
			display: -webkit-box;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		:nth-child(1) {
			margin: 0px;
			&:not(h6) {
				display: inline;
			}
		}

		.attribute-group-icon {
			width: $spacing-24;
			height: $spacing-24;
			margin-left: $spacing-16;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&::before {
				display: inline-block;
			}
		}
	}

	.node-type-container  {
		margin: 0;
		border-radius: $spacing-2;
		width: 100%;

		@include node-level-background('level-0', $grey-6, $color-level0);
		@include node-level-background('level-1', $grey-6, $color-level1);
		@include node-level-background('level-2', $grey-6, $color-level2);
		@include node-level-background('level-3', $grey-6, $color-level3);
		@include node-level-background('level-4', $grey-6, $color-level4);
	}

	.attribute-path-label {
		margin: 0px;
		color: $grey-2;
	}
}
