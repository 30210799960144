@use "sass:math";

.tree-wrapper {
	height: 100%;
	width: 100%;

	.tree-link {
		stroke: $grey-2;
		stroke-width: 2;
	}

	.edit-node-dropdown-menu {
		padding: $spacing-6 0px;
		min-width: fit-content;

		button {
			justify-content: left;
			text-transform: none;
			padding: $spacing-10 $spacing-16;
			font-weight: $font-family-reg-weight;
			color: $color-neutral-primary-text-900;
			background-color: $white;

			&:hover {
				background-color: #F2F2F2;
				color: $color-neutral-primary-text-900;
			}
		}
	}

	.tree-node {
		background-color: white;
		width: 250px;
		height: 100px;
		margin: 48px 15px;
		border-radius: $space-xxs;
		border-width: $space-xxxxs;
		transition: all 0.1s;
		box-shadow: 0px $spacing-4 $spacing-12 rgba($black, 0.25);

		&.selected {
			outline: 3px solid $color-primary;
			box-shadow: 0 $spacing-4 $spacing-8 rgba($color-primary, 0.65);

			&:hover {
				box-shadow: 0 $spacing-4 $spacing-12 rgba($black, 0.25);
			}
		}

		.node-content {
			width: 100%;
			height: 100%;
			position: relative;

			border-radius: $space-xxs;
			border-width: $space-xxxxs;

			display: flex;
			flex-direction: column;
			justify-content: center;

			@mixin node-background (
				$node-level,
				$node-background, $node-stroke,
				$node-text,
				$node-background-completed, $node-stroke-completed,
				$node-text-completed,
				$button-bg-color, $button-text-color,
			) {
				&.#{$node-level} {
					> textarea.node-name-input {
						resize: none;
						color: $node-text;
						margin: 0 auto;
						padding: $spacing-6;
						width: 100%;
						height: min-content;
						text-align: center;
						white-space: pre-wrap;
						border: none;
						box-shadow: none;
						outline: none;
						overflow-y: auto;

						&:focus {
							border: none;
							background-color: transparent;
						}
					}

					color: $node-text;
					border-style: dashed;
					background-color: $node-background;
					border-color: $node-stroke;

					&.complete {
						border-style: solid;
						background-color: $node-background-completed;
						border-color: $node-stroke-completed;
						> textarea.node-name-input {
							color: $node-text-completed;
						}
					}

					.lower-button-container .add-button {
						background-color: $button-bg-color;
						&::before {
							color: $button-text-color;
						}
					}
				}
			}
			//						  level      bg              stroke		             text                    completed bg                completed stroke                completed text      Add button bg               Add button icon
			@include node-background('level-0',	$color-level0,	$color-level0-stroke,	$color-level0-text,		$color-level0,				$color-level0-stroke,			$color-level0-text,	$color-success-500,  		$color-white);
			@include node-background('level-1',	$color-level1,	$color-level1-stroke,	$color-level1-text,		$color-level1-completed,	$color-level1-stroke-completed,	$color-white,		$color-success-500,  		$color-white);
			@include node-background('level-2',	$color-level2,	$color-level2-stroke,	$color-level2-text,		$color-level2-completed,	$color-level2-stroke-completed,	$color-white,		$color-success-500, 	    $color-white);
			@include node-background('level-3',	$color-level3,	$color-level3-stroke,	$color-level3-text,		$color-level3,				$color-level3-stroke,			$color-white, 		$color-success-500, 	    $color-white);
			@include node-background('level-4',	$color-level4,	$color-level4-stroke,	$white,					$color-level4,				$color-level4-stroke,			$color-white, 		$color-success-500, 	    $color-white);

			.side-button-container {
				display: inline-block;
				position: absolute;
				transition: all 0.1s;
				bottom: (calc((12px + $spacing-2) * -1));
				right: (calc((12px + $spacing-2) * -1));

				button {
					padding: 0px;
					border-radius: 50%;
					width: 32px;
					height: 32px;
					font-size: 0.8rem;
					background-color: $white;
					color: $black;
					box-shadow: 0 0 3px 2px rgba($color: #000000, $alpha: 0.1);
					transition: background-color 0.2s ease-in-out;
					z-index: 1;
					&:hover {
						background-color: $color-primary-200;
					}
					&.subanalysis-button {
						transform: rotate(90deg) scaleX(-1);
						&::before {
							font-size: 1.25rem;
						}
					}
				}
			}

			.lower-button-container {
				position: absolute;
				height: 40px;
				bottom: (calc((20px + $spacing-2) * -1));
				width: 100%;

				transition: all 0.1s;

				display: flex;
				justify-content: center;
				align-items: center;
				column-gap: $spacing-12;

				button {
					transition: inherit;
					padding: 0px;
					height: 0px;
					width: 0px;
					border-radius: 50%;
					font-size: 0px;

					&::before {
						margin: 0px;
						max-width: 100%;
						padding-left: 2px;
						opacity: 0;
						transition: all 0.2s ease-in-out;
					}
				}
			}

			&:hover .lower-button-container {
				button {
					transition: inherit;
					width: 40px;
					height: 40px;
					font-size: 1.3rem;
					&::before {
						opacity: 1;
					}

					&.edit-button {
						background-color: $color-secondary-500;
						&::before {
							color: $white;
						}
					}
				}
			}
		}
	}
}
