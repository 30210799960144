﻿.update-system-attributes-modal {
	top: 10%;
	max-width: 800px;
	max-height: 85%;
}

.update-system-node-levels {
	.update-system-node-levels-content {
		padding: 0 $spacing-24;

		.node-level-item {
			padding: $spacing-16 0;
			display: grid;
			grid-template-areas:
			"title action"
			"description action";

			h5,
			p {
				margin: 0;
			}

			h5 {
				grid-area: title;
			}

			p {
				grid-area: description;
			}

			button {
				grid-area: action;
				justify-self: end;
				padding: 0;

				&:after {
					color: $color-neutral-800;
				}
			}
		}
	}
}

.configuration-modal.update-system-metrics-modal {
	width: 100%;
	max-width: 1000px;
	max-height: 700px;

	.system-metrics-configuration {
		border: none;
	}
	
	.justify-content-flex-end-button-group {
		margin-right: $spacing-24;
		margin-bottom: $spacing-24;
	}
}
