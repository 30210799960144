/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Modal
-------------------------------- */
/*
This is the container for modals, which places them above the other content and applies a light overlay to the content
*/

// % protected region % [Change modal styles here] on begin
@use "sass:math";

.modal-container {
	background-color: rgba($page-background, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $z-index-modal;
	overflow: scroll;
}

/*The container for the modal content proper*/
.modal-content {
	position: relative;
	top: 20%;
	border: convert-rem(1px) solid $grey-4;
	background: $background;
	color: get-contrasting-text-color($background);
	overflow: auto;
	border-radius: $btn-radius;
	outline: none;
	padding: $space-md;
	height: fit-content;
	max-height: convert-rem(500px);
	overflow: auto;
	max-width: 400px;
	margin: 0 auto;

	@include breakpoint(sm) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 90%;
		margin: auto;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: $spacing-16;

		.btn {
			padding: 0;
			height: unset;
		}

		h6 {
			margin: 0;
		}
	}

	&__description {
		margin-bottom: $spacing-16;

		p {
			line-height: 1.2;
		}
	}

	.input-group__textarea {

		textarea {
			width: 100%;
			max-width: unset;
		}
	}

	&__actions {
		display: flex;
		justify-content: flex-end;
		margin-top: $space-md;

		.btn:not(:last-child) {
			margin-right: $space-md;
		}
	}

	.btn-group {
		margin-top: $spacing-24;
		margin-left: auto;
		width: max-content;

		.btn {
			margin: 0;
		}
	}
}

.modal-overflow {
	overflow: visible;
}

// % protected region % [Change modal styles here] end

// % protected region % [Add additional modal styles here] on begin
.confirm-modal-container,
.alert-modal-container {
	z-index: $z-index-modal + 100;
}

.modal-height-700 {
	top: calc(50% - convert-rem(350px) + 40px);
	max-height: convert-rem(700px);
}

.modal-width-400 {
	max-width: 400px;
}

.add-edit-attribute-group-modal {
	.icon-list-option-item-wrapper {
		display: flex;
		align-items: center;

		button {
			padding: 0;
			height: unset;

			&:before {
				color: $color-neutral-primary-text-900;
			}
		}

		p {
			margin: 0;
		}
	}
}

.modal-content {
	&.configuration-modal {
		width: 400px;
		padding: 0;

		&.white-header {
			.configuration-modal__header {
				background-color: $color-white;
				color: $color-black;
			}
		}

		.configuration-modal__header {
			background-color: $color-neutral-800;
			color: $color-white;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: $spacing-16;

			&__actions {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
			}

			.btn {
				padding: 0;
				color: $color-white;
				height: $space-xl;
			}

			h5 {
				margin: 0;
			}
		}

		.configuration-modal__body {
			background-color: $color-white;
			color: $color-black;
		}
	}
}
// % protected region % [Add additional modal styles here] end