$list-height: 500px;

.minimal-list-page {
	height: 100%;
	width: 100%;
	background-color: $page-background;
	grid-area: body;
	position: relative;
	display: grid;
	grid-template-rows: 200px minmax(0px, min-content) auto;
	grid-template-areas:
		"top-margin"
		"page-content"
		"bottom-margin";

	@media (max-height: (200px - 80px + $list-height)) {
		grid-template-rows: auto minmax(0px, min-content) auto;
	}

	.list-container {
		width: 326px;
		min-height: 48px;
		max-height: $list-height;
		margin-left: auto;
		margin-right: auto;
		grid-area: page-content;
	}
}