.verify-page-content {
	width: 430px !important;
}

.twofa-input-field {
	display: flex;
	width: 100%;
	max-width: 360px;
	column-gap: 8px;

	.otp-dash {
		margin-top: 32px;
		font-size: 40px;
		color: #808080;
	}

	.otp-input {
		width: 52.67px !important;
		height: 72px !important;
		border: 1px solid #ccc !important;
		border-radius: 2px !important;
		text-align: center !important;
		font-size: 32px !important;
		font-weight: 700 !important;
		line-height: 1 !important;
		box-shadow: none !important;

		&:hover {
			background-color: #FFFBE6 !important;
			border-color: #000 !important;
		}
	}
}
