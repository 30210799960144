/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Spacing
https://medium.com/codyhouse/create-your-design-system-part-4-spacing-895c9213e2b9
-------------------------------- */

/* 
Spacing are currently calculated in harmony, to change the values you can add your own property.
*/

// % protected region % [Make changes to spacing variables] on begin
$space-unit: 1rem;
/**
	FIGMA VARIABLES
**/
$spacing-2: calc(0.125 * #{$space-unit});	// 2px
$spacing-4: calc(0.25 * #{$space-unit});	// 4px
$spacing-6: calc(0.375 * #{$space-unit});	// 6px
$spacing-8: calc(0.5 * #{$space-unit});		// 8px
$spacing-10: calc(0.65 * #{$space-unit});
$spacing-12: calc(0.75 * #{$space-unit});	// 12px
$spacing-16: calc(1 * #{$space-unit});		// 16px
$spacing-24: calc(1.5 * #{$space-unit});	// 24px
$spacing-32: calc(2 * #{$space-unit});		// 32px
$spacing-48: calc(3 * #{$space-unit});		// 48px
$spacing-64: calc(4 * #{$space-unit});		// 64px
$spacing-96: calc(6 * #{$space-unit});		// 96px
$spacing-128: calc(8 * #{$space-unit});		// 128px

/**
	BOT VARIABLES
**/

/* spacing values */
$space-xxxxs: $spacing-2;
$space-xxxs: $spacing-4;
$space-xxs: $spacing-8;
$space-xs: $spacing-12;
$space-sm: $spacing-16;
$space-md: $spacing-24;
$space-lg: $spacing-32;
$space-xl: $spacing-48;
$space-xxl: $spacing-64;
$space-xxxl: $spacing-96;
$space-xxxxl: $spacing-128;

/* components padding */
$component-padding: $space-sm;
//****************-------SPACING VARIABLES--------****************
$space-horizontal: 2rem;
$space-vertical: 1rem;

// optional - edit space unit and padding of all components at a specific breakpoint
@include breakpoint(md) {
	$space-uni: 1.25rem;
	$component-padding: $space-md;
}

// % protected region % [Make changes to spacing variables] end

// % protected region % [Add additional spacing variables here] off begin
// % protected region % [Add additional spacing variables here] end