﻿.map-view-component {
	height: 100%;
	position: relative;

	.spinner-container.inline {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}

	.engine-control-hint {
		right: 60px;
	}

	.deck-tooltip {
		font-size: 15px;
		font-family: $body-font-family;
		color: $color-neutral-primary-text-900;
		background-color: $color-neutral-50;
		border: 1px solid $color-neutral-primary-text-900;
		padding: $spacing-6 $spacing-12;
		border-radius: 0.2rem;
		font-weight: bold;
	}
}
