.create-organisation-modal {
	button {
		&:first-child {
			padding-left: 0;
		}
	}
}

.change-watermark-modal {
	.btn-group {
		button {
			height: unset;
			padding-top: 1rem;
			padding-right: 1rem;
			padding-bottom: 0;
		}
	}
}