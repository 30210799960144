.tree-view {
	margin: $spacing-12;

	h5,
	p,
	ul,
	.input-group__checkbox {
		margin: 0;
	}

	h5 {
		padding-bottom: 0;
	}

	.input-group__checkbox {
		align-items: center;

		input {
			border-color: $color-primary;

			&:after {
				color: $color-primary;
			}
		}

		label {
			color: $white;
		}
	}

	ul {
		padding-left: $spacing-16;
		list-style-type: none;

		.tree-view-item {
			margin: $spacing-12 0;

			&__label {
				display: grid;
				grid-template-areas: "caret label";
				grid-auto-columns: 24px 1fr;
				align-items: center;
				gap: $spacing-8;

				.caret {
					grid-area: caret;
					cursor: pointer;
					user-select: none;
					width: 24px;
					height: 24px;
					@include transform-timing-specific(transform, 0.3s);

					&:before {
						font-family: $lightning-icon-name;
						content: "\e5cf";
						font-size: 1.5rem;
						width: 24px;
						height: 24px;
						margin-top: -5px;
						color: $white;
						display: inline-block;
					}

					&.collapsed {
						transform: rotate(-90deg);
					}
				}

				.input-group__checkbox {
					grid-area: label;
				}
			}

			.nested {
				transition: all 0.3s ease-in-out;
				max-height: 0;
				overflow: hidden;
				//opacity: 0;
				//visibility: hidden;
			}

			.active {
				max-height: 10000px;
				//opacity: 1;
				//visibility: visible;
			}
		}
	}

	> ul {
		padding-left: 0;;
	}
}