/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Navigation
-------------------------------- */
/*
Styling for basic navigation, vertical navigation and horizontal navigation.
*/

/****** General navigation styling shared amongst all navigation *****/
// % protected region % [Change generic navigation styles here] on begin
.nav {
	display: flex;
	background-color: $color-neutral-800;

	ul {
		width: 100%;
		margin-bottom: 0;
		color: $white;

		li {
			display: flex;
			flex-direction: column;
			list-style: none;
			position: relative;

			.nav__link {
				color: $white;
				text-decoration: none;
				padding: $space-xxs;
				display: block;
				cursor: pointer;
				transition: none;
				border-bottom: none;
				font-weight: 600;
			}

			span {
				white-space: nowrap;
			}

			&.nav-logo {
				min-width: fit-content;
				.image {
					height: calc($space-sm - $space-xxxxs);
					content: url('../../../../Images/Logos/achilles-logo-text-only-white.svg');
				}
			}

			&.system-name{
				min-width: max-content;
				padding-top: 1px;
			}

			&.system-engine-link {
				min-width: max-content;
				border-top: $space-xxxxs solid transparent;
				border-bottom: $space-xxxxs solid transparent;
				&.active {
					.nav__link {
						color: $color-primary-500;
					}
					border-bottom: 2px solid $color-primary-500;
				}

				&:hover,
				&:focus {
					@include transform-timing($animation-time);

					.nav__link {
						color: $color-primary-500;
						@include transform-timing($animation-time);
					}

					//This is for sublinks
					ul li a {
						color: $color-primary-500;
						transition: none;
					}
				}
			}

			@import 'sublinks-navigation.scss';
		}
	}
}

hr {
	height: 1px;
	background-color: $color-neutral-50;
	border: none;
}
// % protected region % [Change generic navigation styles here] end

/****** Horizontal navigation (usually placed left) *****/
/****** Note that if you are working on the expanded navigation ie the a tag to expand the nav - go to expanded-navigation.scss ******/
// % protected region % [Change vertical navigation styles here] off begin
.nav--vertical {
	overflow: auto;
	flex-direction: column;
	left: 0;
	grid-area: navvertical;
	height: 100%;
	box-shadow: convert-rem(-2px) convert-rem(4px) convert-rem(7px) convert-rem(-1px);

	>ul {
		margin: 0;
		padding: 0;

		>li {
			display: block;

			&:hover,
			&:active,
			&:focus,
			&.active {
				color: get-contrasting-text-color($grey-1);
			}

			//position of sublinks for vertical nav
			.nav__sublinks {
				left: 100%;
				top: 0;
				max-width: convert-rem(0px);
				width: 0;
				max-height: 70vh;
				overflow: auto;
				@include cubic-bezier-ease-in(max-width, $animation-time);

				li {
					border-bottom: convert-rem(1px) solid get-contrasting-text-color($grey-1);
				}
			}

			.nav__sublinks--visible {
				max-width: convert-rem(9999px);
				width: auto;
				min-width: convert-rem(120px);

				span {
					width: 100%;
				}
			}
		}
	}

}
// % protected region % [Change vertical navigation styles here] end

/****** Horizontal navigation (usually placed top) *****/
// % protected region % [Change horizontal navigation styles here] on begin
.nav--horizontal {
	width: 100%;
	display: flex;
	flex-direction: row;
	grid-area: navhorizontal;
	box-shadow: convert-rem(1px) convert-rem(0px) convert-rem(4px) convert-rem(-1px);
	align-items: center;
	justify-content: space-between;
	padding-right: $space-lg;
	padding-left: $space-lg;

	ul {
		display: flex;
		flex: 1;
		width: auto;
		margin-top: 0;
		padding-left: 0;
		&:not(:first-child):not(:last-child) {
			justify-content: center;
			li {
				text-align: center;
				min-width: 96px;
			}
		}

		.icon-menu::before, .icon-person::before {
			margin: 0px;
		}

		// left group
		&:first-child {
			li:not(:first-child) {
				margin-left: $spacing-24;
			}
		}

		// last group
		&:last-child {
			flex-direction: row-reverse;
		}

		.icon-menu, .icon-person {
			::after {
				display: none;
			}

			.dropdown.icon {
				display: none;
			}
		}

		li {
			height: 40px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			//position of sublinks for horizontal nav
			.nav__sublinks {
				top: calc(100% + (#{2rem}*0.4));
				left: 0;
				flex-direction: column;
				max-height: 0;
				height: 0;
				@include cubic-bezier-ease-in(max-height, $animation-time);

				li {
					border-bottom: none;
				}
			}

			.nav__sublinks--visible {
				max-height: convert-rem(9999px);
				height: auto;

				span {
					max-height: convert-rem(9999px);
					height: auto;
				}
			}
		}
	}
}
// % protected region % [Change horizontal navigation styles here] end

/****** Tabs *****/
// % protected region % [Change tabs styles here] off begin
.tabs {
	margin-bottom: $space-xl;
	font-size: $text-md;

	ul {
		padding-left: unset;
		display: flex;
		flex-direction: row;
		list-style: none;

		li {
			cursor: pointer;

			&.disabled {
				cursor: not-allowed;
			}

			&:not(:last-child) {
				margin-right: $space-md;
			}

			a {
				color: get-contrasting-text-color($grey-1);
				text-decoration: none;
			}
		}
	}

	.active {
		@include text-shadow($color-primary);

		a {
			border-bottom: convert-rem(2px) solid;
		}
	}
}
// % protected region % [Change tabs styles here] end

// % protected region % [Change breadcrumbs styles here] off begin
/****** Breadcrumbs *****/
.breadcrumbs {
	padding-left: unset;
	display: flex;
	flex-direction: row;
	list-style: none;
	align-items: center;

	li {
		&.disabled {
			cursor: not-allowed;
		}

		&:not(:last-child) {
			margin-right: $space-sm;
		}

		&:before {
			vertical-align: middle;
		}

		a {
			color: get-contrasting-text-color($background);
			text-decoration: none;
			cursor: pointer;
		}
	}
}
// % protected region % [Change breadcrumbs styles here] end

/************ MOBILE NAVIGATION ***********/
// % protected region % [Change mobile navigation styles here] on begin
// removed - mobile support is not a requirement of Achilles
// % protected region % [Change mobile navigation styles here] end

// % protected region % [Add additional navigation styles here] off begin
// % protected region % [Add additional navigation styles here] end